<template>
    <div class="adminInstructorRow">
        <div class="disabledOverflow" v-if="disabled"></div>
        <div class="aiRight">Dodaj sliko:</div>
        <label class="aiRight addImageButton iconBtn flexElement" :for="qIndex+'instructor'+index">
            <img src="../../../assets/img/add_icon_small.svg" alt="Add">
            <input type="file" accept="image/*" @change="uploadImage($event)" :id="qIndex+'instructor'+index">
        </label>
        <input type="text" class="textAreaStyle roundedStyle aiRight" placeholder="Vpišite polno ime predavatelja" v-model="item.placeholder" @blur="handleChange">
        <button v-if="!disabled && showDelete" @click="$emit('delete')" class="iconBtn aiRight"><img src="../../../assets/img/trash_icon.svg" alt="Delete"></button>
        <div class="fileName" v-if="isImage"><a class="linkStyleBtn" :href="prependUrl(item.image)" target="_blank">Poglej sliko</a></div>
    </div>
</template>

<script>
export default {
    name: "AdmInstructorsRatingRow",
    props: {item: Object, index: String|Number, qIndex: String|Number, disabled: Boolean, showDelete: Boolean},
    data() {
        return {
            isImage: this.item.image ? true : false,
        }
    },
    methods: {
        uploadImage(event) {
            let image = event.target.files[0];

            let reader = new FileReader();
            reader.readAsDataURL(image);

            this.uploadImageDb(image).then(url => {
                this.item.image = url;
                this.isImage = true;
                this.handleChange();
            });
        },
        handleChange() {
            this.$emit("change")
        }
    }
}
</script>

<style scoped>

.adminInstructorRow {
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px 0;
}

.roundedStyle {
    width: 300px;
}

.addImageButton {
    cursor: pointer;
}

.disabledOverflow {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    opacity: 0.8;
    z-index: 9;
}

</style>