<template>
    <div class="vprasanje-container lestvica1-5">
        <span class="st-vprasanja">{{ question.order }}. {{ __("analysis.question", locale) }}</span>
        <span class="vprasanje">{{ question.name }}</span>
        <div v-if="question.special_type == 'MBA'">

            <div class="grafi">
                <div class="graf" style="width: 80%">
                    <canvas :id="'graph-scale-rating'+question.id+'q'" style="width:100%;max-width:800px"></canvas>
                </div>
            </div>
            <div class="no-page-break mb-1" v-for="(a, i) in dataAnalysis" v-if="a.placeholder" :key="i+'pa'">
                <span class="komentar">Q-{{ i + 1 }}: {{
                        a.placeholder ? a.placeholder : question.name
                    }} <br> {{ __("analysis.average", locale) }}: <b>{{ roundMix(average(a.graph)) }}</b> </span>
            </div>

        </div>
        <div v-else class="no-page-break" v-for="(a, i) in dataAnalysis" :key="i+'pa'">
            <table class="st4">
                <tr>
                    <th>{{ a.placeholder }}</th>
                    <th>{{ __("analysis.scale", locale) }}</th>
                    <th>{{ __("analysis.answer", locale) }}</th>
                    <th>{{ __("analysis.percentage", locale) }}</th>
                </tr>
                <tr v-for="(g,i) in a.graph" :key="'aGraph'+i">
                    <td>&nbsp;</td>
                    <td>{{ i }}</td>
                    <td>{{ g }}</td>
                    <td>{{ roundMix(((g / mySum(a.graph)) * 100)) }}%</td>
                </tr>
                <tr class="final">
                    <td>{{ __("analysis.sumarium", locale) }}</td>
                    <td>&nbsp;</td>
                    <td>{{ mySum(a.graph) }}</td>
                    <td>100%</td>
                </tr>
                <tr class="average final">
                    <td>{{ __("analysis.average", locale) }}</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>{{ roundMix(average(a.graph)) }}</td>
                </tr>
            </table>
            <span v-if="a.explanations" class="vprasanje komentar-naslov">{{ __("analysis.comment", locale) }}</span>
            <div v-if="a.explanations">
                <div v-for="(c,i) in a.explanations" :key="i+'feedback'">
                    <span class="komentar">{{ c.value }}</span>
                </div>
            </div>
            <div class="grafi">
                <div class="graf">
                    <canvas :id="'graph-scale-rating'+a.id+'pa'" style="width:100%;max-width:800px"></canvas>
                </div>
            </div>
        </div>

    </div>
</template>


<script>
import {Chart, registerables} from 'chart.js';
import scaleRatingGraph from '../../../charts/scaleRating.js'


export default {
    name: "AnlScaleRating",
    props: {locale: String, question: Object},
    data() {
        return {
            dataAnalysis: null,
        }
    },
    methods: {
        async load() {
            let url = "/analysis/query-answer/" + this.question.id;
            await api.post(url).then(response => {
                this.dataAnalysis = response.data.data;
                this.$nextTick(function () {
                    this.drawGraphs();
                })
            }).finally(() => {
                this.$emit("loaded");
            });
        },
        drawGraphs() {
            let tmp = JSON.parse(JSON.stringify(this.dataAnalysis));
            if (this.question.special_type == 'MBA') {
                this.drawStackedGraph(document.getElementById('graph-scale-rating' + this.question.id + 'q'), tmp);
            } else {
                for (let i = 0; i < tmp.length; i += 1) {
                    this.drawGraph(document.getElementById('graph-scale-rating' + tmp[i]["id"] + 'pa'), tmp[i]);
                }
            }
        },
        mySum(graph) {
            let sum = Object.values(graph).reduce((partial_sum, a) => partial_sum + a, 0);
            return sum === 0 ? 1 : sum;
        },
        drawStackedGraph(ctx, d, format = "bar") {
            let lables = d.map((i) => {
                if (!i.placeholder)
                    return "";
                return "Q-" + (parseInt(d.indexOf(i)) + 1);
            });
            let colors = [
                "#FF4069",
                "#069BFF",
                "#22CFCF",
                "#F7985D",
                "#80E173"
            ];
            let datasets = [1, 2, 3, 4, 5].map((c) => {
                return {
                    label: c,
                    data: this.getPercents(c),
                    backgroundColor: colors[c - 1],
                }
            });
            let data = {
                labels: lables,
                datasets: datasets,
            };
            const config = {
                type: 'bar',
                data: data,
                options: {
                    plugins: {
                        legend: {
                            display: true,
                        },
                        datalabels: {
                            display: true,
                            align: 'center',
                            anchor: 'center',
                            font: {
                                size: 10
                            },
                            formatter: function (value, index, values) {
                                if (value === 0)
                                    return "";
                                if (value !== 0) {
                                    return value + "%";
                                }
                            }
                        }
                    },
                    responsive: true,
                    scales: {
                        x: {
                            stacked: true,

                        },
                        y: {
                            stacked: true,
                            min: 0, // minimum value
                            max: 100 // maximum value
                        }
                    }
                },
                 animation: {
        duration: 0
    },

            };
            // console.log(config);
            new Chart(ctx, config);
        },
        drawGraph(ctx, data, format = "bar") {
            let labels = Object.keys(data["graph"]);
            let graphData = {
                type: format,
                data: {
                    labels: labels,
                    datasets: [{
                        backgroundColor: this.colors1to5(),
                        data: Object.values(data["graph"])
                    }]
                },
                options: {
                    plugins: {
                        legend: {display: false},
                        title: {
                            display: true,
                            text: data["placeholder"],
                        },
                        datalabels: {
                            display: true,
                            align: 'center',
                            anchor: 'center',
                            font: {
                                size: 14
                            },
                            color: "#ffffff",
                            formatter: function (value, index, values) {
                                if (value === 0)
                                    return "";
                                if (value !== 0) {
                                    return value;
                                }
                            }
                        }

                    },
                     animation: {
        duration: 0
    },
                }
            };
            new Chart(ctx, graphData);
        },
        getPercents(mark) {
            return this.dataAnalysis.map((e) => {
                return this.roundMix(e.graph[mark] / this.arraySum(Object.values(e.graph)) * 100);
            })
        }
    },

    mounted() {
        this.$nextTick(function () {
            if (this.dataAnalysis)
                this.drawGraphs();
        });
    }
    ,
    nextTick(callback, context) {
    }
    ,
    created() {
        this.load();
    }
    ,
}

</script>

<style scoped>

.vprasanje-container {
    page-break-inside: avoid;
}

.komentar-naslov {
    margin-top: 30px;
    font-weight: bold;
    margin-bottom: 6px;
}

.graf {
    width: 100%;
}

.komentar {
    font-size: 14px;
    display: block;
    padding: 5px 10px;
    margin-bottom: 3px;
    border-radius: 4px;
    width: 100%;
    background-color: #e6e6e6;
}

.st-vprasanja {
    margin-top: 40px;
    display: block;
    font-size: 14px;
    font-weight: 300;
}

.mb-1 {
    margin-bottom: 12px !important;
}

.vprasanje {
    font-weight: 600;
    font-size: 14px;
    display: block;
}

tr td, tr th {
    text-align: center;
}

.final td {
    border-top: 1px solid black;
    font-weight: bold;
    padding-top: 10px;
    text-transform: uppercase;
}

.final.average td {
    border-top: none;
    padding-top: 0;
}

th {
    padding-bottom: 10px;
    font-weight: bold;
    text-transform: uppercase;
    border-bottom: 1px solid black;
}

table td {
    padding: 3px 0;
}

table {
    width: 100%;
    margin-top: 30px;
    margin-left: -2px;
    font-size: 14px;
}

table tr td:last-child, table tr th:last-child, table tr td:last-child, table tr th:last-child {
    text-align: right;
}

table tr td:first-child, table tr th:first-child, table tr td:first-child, table tr th:first-child {
    text-align: left;
}

.grafi {
    margin-top: 20px;
    justify-content: center;
    display: flex;
    flex-flow: inherit;
}

.graf {
    background-color: #fff;
    width: 50%;
}

.graf canvas {
    padding: 10px;;
}

.st4 tr td {
    width: 25%;
}

</style>
