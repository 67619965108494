<template>
    <div class="componentContainer">
        <div class="thankYouMessageContainer tyHeightTop">
            <div class="centeredContent">
                <div class="thankYouContainer pre-line finishTxt">
                    {{ survey.finish_txt }}
                </div>
                <div class="thankYouContainer">
                    <h1>{{ survey.thank_you_txt }}</h1>
                </div>
            </div>
        </div>
        <div class="thankYouPageImgBackground tyHeightBottom noSelect">
            <img class="imageBackground" :src="setSrc" />
            <div class="imageOverlay"></div>
            <div class="imageContent">
                <div class="socialLogos">
                    <a v-for="c in survey.connections" :key="c.id" :href="c.url" class="socialLogo" target="_blank"><img :src="prependUrl('front_'+c.icon_src)" :alt="c.link_name"></a>
                </div>
                <div class="author">
                    <div class="authorName">{{ survey.title }}</div>
                    <div class="authorAddress">{{ survey.address }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "ThankYouPage",
    props: { survey: Object },
    methods: {
        finishSurvey() {
            let url = "/answer/finish-survey/" + localStorage.getItem("progressToken" + this.$route.params.token);

            api.post(url).then(response => {
                console.log(response)
            })
        }
    },
    mounted() {
        const header = document.getElementsByTagName("header")[0];
        const logo = document.getElementsByClassName("logoImg")[0];
        // logo.style = "filter: invert(1)";
        header.style = "color: #000";
        this.finishSurvey();
    },
    computed: {
        setSrc() {
            return this.survey.foot_img ? this.prependUrl(this.survey.foot_img) : require("../../assets/img/background2_desktop.jpg");
        }
    }
};
</script>
<style scoped>
.tyHeightTop {
    height: 70%;
}

.tyHeightBottom {
    height: 30%;
}


.centeredContent {
    height: 100%;
    padding-top: 20%;
    flex-direction: column;
}

.thankYouContainer {
    padding: 20px;
    text-align: center;
    font-weight: 600;
    max-width: 1200px;
}

.imageContent {
    padding: 50px 20px;
}

.socialLogo {
    height: 43px;
    width: 43px;
    padding: 5px;
}

.socialLogo img {
    height: inherit;
    width: inherit;
}

.socialLogo img:hover {
    transform: scale(1.1);
}

.author {
    text-align: center;
    font-size: 10px;
    margin-top: 10px;
}

.authorName {
    font-weight: bold;
    padding: 10px 0;
}

/* .finishTxt {
    font-size: 
} */

@media (min-width:801px) {

    .componentContainer{
        padding-top:88px;
    }

    .centeredContent {
        padding-top: unset;
    }

    .imageContent {
        padding-top: 100px;
    }

    .socialLogo {
        height: 54px;
        width: 54px;
        padding: 15px;
    }

    .author {
        font-size: 15px;
        margin-top: 14px;
    }

    .authorName {
        font-weight: bold;
        padding: 15px 0;
    }

    .tyHeightTop, .tyHeightBottom {
        height: 50%;
    }


}
</style>
