<template>
    <div class="adminAnswerContainer">
        <div class="flexElement">
            <input type="text" class="roundedStyle textAreaStyle" placeholder="Vpišite besedilo vprašanja" v-model="item.name" @blur="changeName">
        </div>

        <div class="adminAnswerMargin">
            <star-rating inactive-color="#fff" border-color="var(--dark-grey)" :border-width="1"
                         active-color="var(--main-color)" active-border-color="var(--main-color)" :show-rating="false"
                         :read-only="true" v-model="value"/>
        </div>

        <!-- Opisi zvezdic so skriti -->
        <div v-if="false" class="adminAnswersInput noPaddingTop">
            <div v-for="(rating, i) in item.ratings" :key="i+'star'" class="adminAnswersInputSingle">
                <div class="starRow">
                    <single-star v-for="j in rating.value" :key="j+'singlestar'" class="singleStar"/>
                </div>
                <input type="text" class="roundedStyle textAreaStyle" placeholder="Prikazan opis pri izbrani oceni" v-model="rating.title">
            </div>
        </div>

        <div class="shortRowContainer">
            <create-survey-input :data="inputFileData" @change="handleImage"/>
        </div>

        <div class="admConditionContainer">
            <checkbox-group :options="conditionalCheckbox" width="100%" :name="item.type + item.index"
                            @change="handleConditional"/>
            <div class="admConditionalInput normalMargin">
                <div class="admLabel">Besedilo vprašanja</div>
                <input type="text" class="textAreaStyle roundedStyle" v-model="item.summary.testimonial_text">
            </div>
            <div class="flexElement">
                <testimonial-upload name="image_1" :oldSrc="item.summary.image_1" @upload="handleUpload"></testimonial-upload>
                <testimonial-upload name="image_2" :oldSrc="item.summary.image_2"  @upload="handleUpload"></testimonial-upload>
                <testimonial-upload name="image_3" :oldSrc="item.summary.image_3"  @upload="handleUpload"></testimonial-upload>
            </div>
            <div class="admConditionalInput normalMargin">
                <div class="admLabel">Naslov</div>
                <input type="text" class="textAreaStyle roundedStyle" placeholder="npr. PODAJTE SVOJE MNENJE"
                       v-model="item.summary.title">
            </div>

            <div class="adminAnswersInput">
                <div v-for="(a,i) in cols" :key="i+'cols'" class="adminAnswersInputSingle flexElement">
                    <input type="text" class="roundedStyle textAreaStyle" :placeholder="colsPlaceholders[i]" v-model="a.column_name">
                    <button v-if="i > 2" @click="deleteCol(i)" class="iconBtn"><img
                        src="../../../assets/img/trash_icon.svg" alt="Delete"></button>
                </div>
                <div class="adminAnswersInputSingle">
                    <div class="roundedStyle textAreaStyle disabledRoundedStyle">
                        <span>Novo polje</span>
                        <button class="addOptionBtn iconBtn" @click="addCol"><img
                            src="../../../assets/img/add_icon_small.svg" alt="ADD"></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "AdmReview",
    props: {item: Object},
    data() {
        return {
            inputFileData: {type: "file", name: "Izberi sliko naslovnice", placeholder: "Izberite datoteko"},
            noteData: {type: "text", name: "Dodaj opombo", placeholder: "Vpišite besedilo opombe"},
            conditionalCheckbox: [{
                boldLabel: "POGOJ:",
                label: "Ali želite pridobiti oddajo pozitivnega mnenja z oceno 4-5 (zvezde)",
                checked: this.setBool(this.item.cond)
            }],
            cond: [
                {
                    label: "DA",
                    value: true,
                    checked: true,
                }, {
                    label: "NE",
                    value: false,
                }
            ],
            value: 4,
            cols: [
                {column_name: null},
                {column_name: null},
                {column_name: null},
            ],
            colsPlaceholders: ["npr. Ime in priimek", "npr. Podjetje", "npr. Funkcija v podjetju"]
        }
    },
    methods: {
        changeName() {
            if (this.item.name)
                this.item.qName = this.item.name;
        },
        handleUpload(url, name) {
            this.item.summary[name] = url;
        },
        handleImage(value) {
            this.item.banner = value;
        },
        handleConditional(value) {
            this.item.cond = !!value.length;
        },
        addCol() {
            this.cols.push({column_name: null});
            this.colsPlaceholders.push("Vpišite ime novega polja")
            this.colChange();
        },
        deleteCol(index) {
            this.cols.splice(index, 1);
            this.colsPlaceholders.splice(index, 1);
            this.colChange();
        },
        setRatings() {
            this.item.ratings = [
                {
                    value: 1,
                    title: null,
                },
                {
                    value: 2,
                    title: null,
                },
                {
                    value: 3,
                    title: null,
                },
                {
                    value: 4,
                    title: null,
                },
                {
                    value: 5,
                    title: null,
                }];
        },
        colChange() {
            this.item.summary.cols = this.cols;
        }
    },
    created() {
        if (!this.item.summary)
            this.item.summary = {};
        if (!this.item.summary.cols) {
            this.colChange();
        } else {
            this.cols = this.item.summary.cols;
        }
        if (!this.item.ratings || this.item.ratings.length < 1) {
            this.setRatings();
        }
    },
}
</script>

<style scoped>

.adminAnswerMargin {
    margin-top: 50px;
}

.Rectangle-Copy {
    width: 98%;
    margin: 30px 120px 48.2px 10.2px;
    padding: 10px 3.1px 68px 6.1px;
    border-radius: 12px;
    background-color: #f6f6f6;
}

.bold-text {
    font-weight: 600;
}

.container-padding {
    padding: 40px;
}

.checkbox-yes-no-option {
    padding: 5px 0 0 15px;
    display: flex;
    flex-flow: inherit;
}

.checkbox-yes-no-option > div {
    width: fit-content;
}

.adminAnswersInput {
    flex-direction: column;
}

.adminAnswersInput input, .disabledRoundedStyle {
    width: 800px;
}

.adminAnswersInputSingle {
    width: 100%;
}

.starRow {
    display: flex;
    align-items: flex-end;
}

.singleStar {
    margin-right: 10px;
    width: 30px;
}

.noPaddingTop {
    padding-top: 0;
}

</style>