<template>
   <transition name="fade">
        <div class="confirmDialogueModal" v-if="isVisible" @click="_cancel">
            <div class="confirmDialougeWindow" @click.stop>
                <div class="confirmDialogueMessage">{{ message }}</div>
                <div class="confirmDialogueBtns">
                    <button class="confirmDialogueButton roundedStyle mainColorBtn" @click="_confirm">{{ okBtn }}</button>
                    <button class="confirmDialogueButton roundedStyle greyBtn" @click="_cancel">{{ cancelBtn }}</button>
                </div>
            </div>
        </div>
   </transition>

</template>

<script>
export default {
    name: "ConfirmDialogue",
    props: { okBtn: String, cancelBtn: String, message: String},
    data() {
        return {
            isVisible: false,

            resolvePromise: undefined,
            rejectPromise: undefined,
        }
    },
    methods: {
        open() {
            this.isVisible = true;
            return new Promise((resolve, reject) => {
                this.resolvePromise = resolve
                this.rejectPromise = reject
            })
        },
        close() {
            this.isVisible = false
        },
        _confirm() {
            this.close();
            this.resolvePromise(true);
        },

        _cancel() {
            this.close();
            this.resolvePromise(false);
        },
    },
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.confirmDialogueModal {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    z-index: 1000;
}

.confirmDialougeWindow {
    background: #fff;
    border-radius: 5px;
    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 600px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding: 1rem;
}

.confirmDialogueMessage {
    padding: 1.5rem 0 2rem 0;
}

.confirmDialogueBtns {
    border-top: #d8d8d8 solid 1px;
    padding-top: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.confirmDialogueButton {
    width: 160px;
    padding: 8px;
}
</style>