<template>
    <div class="scaleRatingsRow" :class="{imageRow: image, scaleStyleRow: !image}">
        <div class="scaleRatingsDescription" v-if="!plain">
            <div v-if="image" class="scaleRatingImage">
                <img :src="prependUrl(option.image)" :alt="option.name">
                <div class="descriptionName">{{ option.placeholder }}</div>
            </div>
            <span v-else>{{ option.placeholder }}</span>
        </div>
        <div class="scaleRatingsContent" :class="{fullContent: plain}">
            <vue-slider v-model="newValue" :class="{hideSliderDot: !newValue}" :data="scaleData" tooltip="none" :hide-label="true" @change="handleChange" />
            <button v-if="!mandatory && newValue" @click="removeRating" class="iconBtn"><img src="../assets/img/cancel_icon.svg" alt="Cancel"></button>
        </div>
    </div>
</template>

<script>
export default {
    name: "ScaleRatingsRow",
    props: { option: String|Object, size: Number, image: Boolean, plain: Boolean, value: Object, mandatory: Boolean|Number },
    data() {
        return {
            newValue: this.value.value,
        }
    },
    methods: {
        handleChange() {
            this.$emit("change", this.newValue);
        },
        removeRating() {
            this.newValue = null;
            this.handleChange();
        }
    },
    computed: {
        scaleData() {
            let a = {
                "1" : "",
                "2" : "",
                "3" : "",
                "4" : "",
                "5" : "",
            }
            return a
        },
    },
    watch: {
        value: {
            deep: true,
            handler(value) {
                if(value) {
                    this.newValue = value.value;
                }                
            }
        },
    },
}
</script>

<style scoped>

.scaleRatingsContent {
	padding: 0 5px;
    position: relative;
}

.scaleRatingImage {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.descriptionName {
    position: absolute;
    bottom: 100%;
    text-align: center;
}

img {
    border-radius: 50%;
    height: 60px;
    width: 60px;
    object-fit: cover;
}

.descriptionName {
    position: absolute;
    /* bottom: 258%; */
    text-align: left;
    top: 100%;
    width: 250px;
    left: 6px;
    overflow-wrap: break-word;
}

.iconBtn {
    position: absolute;
    right: -20px;
    top: -27px;
}

.iconBtn img {
    height: 20px;
    width: 20px;
}

.scaleStyleRow {
    padding: 20px 0;
}

@media (min-width:801px) {
    .scaleRatingImage {
        flex-direction: row;
    }

    .descriptionName {
        position: relative;
        bottom: unset;
        margin-left: 20px;
    }

    .iconBtn {
        top: -2px;
        right: -57px;
    }
}

</style>