<template>
    <div class="text-center mb">
        <div class="progressBarContainer">
            <div class="progressBarOutline">
                <div class="progressBar" style="height:17px;" :style="{width: percentage}">
                    <span v-if="false" class="questionNumber">{{ percentage }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ProgressBar",
    props: {qNum: Number, qSum: Number},
    computed: {
        percentage() {
            return (((this.qNum / this.qSum) * 100) | 0)+"%";
        }
    }
}
</script>

<style scoped>
.mb{
    margin-bottom:50px;
}
.questionNumber {
    color: #fff;
    font-weight: bold;
    font-size: 15px;
}

.progressBarContainer {
    width: 100%;
    text-align: center;
    margin: auto;
}

.progressBarOutline {
    border: 1px solid var(--dark-grey);
    border-radius: 500px;
}

.progressBar {
    background-color: var(--dark-grey);
    border-radius:500px;
    animation: progressBar 3s ease-in-out;
    animation-fill-mode:both;
}




</style>