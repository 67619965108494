<template>
    <div v-if="notFound" class="relativeConteiner">
        <div class="centeredAbsolute">
            Ups, ta stran ne obstaja
        </div>
    </div>
    <div v-else class="relativeContainer">
        <div v-if="loading" class="loaderContainerAbsolute">
            <div class="loader"></div>
        </div>
        <div v-if="loading" class="loaderOverlay"></div>

        <div class="surveyListTopBar flexElement">
            <input type="text" class="roundedStyle textAreaStyle" placeholder="Išči po anketah" @input="handleSearch" v-model="search">
            <div class="selectByGroup">
                <button v-if="false" class="linkStyleBtn" @click="search = null; load()">Prikaži vse ankete</button>

                <span>Uredi po:</span>
                <select class="roundedStyle selectStyle" v-model="order" @change="load">
                    <option value="updated_at" selected>datumu</option>
                    <option value="intern_name">naslovu</option>
                    <option value="id">idju</option>
                </select>
            </div>
        </div>
        <div class="surveyListTable">
            <div class="surveyListTableHeader flexElement">
                <div class="tableCol tableColName" style="width:6%;padding-left:10px">ID</div>
                <div class="tableCol tableColName">Naslov</div>
                <div class="tableCol tableColDate">Zadnji vnos</div>
                <div class="tableCol tableColAuthor">Avtor</div>
                <div class="tableCol tableColAuthor">F/I</div>
                <div class="tableCol tableColActions">Dejanja</div>
            </div>
            <div class="surveyListTableContent" 
                    v-infinite-scroll="handleScrolling"
                    :infinite-scroll-disabled="disableScroll">
                <drag-survey-row v-for="item in itemsFilter" :key="item.id" :survey="item" :list="true" @reload="load"/>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "SurveyList",
    props: {filter: String},
    data() {
        return {
            order: "id",
            items: [],
            search: null,
            loading: false,
            timer: null,
            notFound: false,
            pagination: 10,
            disableScroll: true,
        }
    },
    methods: {
        handleSearch() {
            clearTimeout(this.timer);
            this.timer = setTimeout(this.load, 700);
        },
        load() {
            this.loading = true;
            this.notFound = false;
            let url;
            let data = {
                search: this.search,
                searchCols: ["intern_name", "name"],
                orderCols: [[
                    this.order
                ]],
            };
            if (this.filter === "my-surveys") {
                url = "/survey/index/" + this.$store.state.auth.user.id;
            } else if (this.filter == "active-surveys") {
                url = "/survey/index";
                data["status"] = "published";
            } else {
                // url = "/survey/index";
                this.notFound = true;
                return false
            }
            api.post(url, data).then(response => {
                this.items = response.data.data;
                this.loading = false;
                this.disableScroll = false;
            }).catch(error => {
                this.loading = false;
                let errors = Object.keys(this.errorCatching(error).errors);
                this.$root.$children[0].$refs.warningRef.show("Prišlo je do napake. Koda napake: " + errors);
            })
        },
        setSidebar() {
            let name;
            switch (this.filter) {
                case "my-surveys":
                    name = "Moje ankete";
                    break
                case "all-surveys":
                    name = "Vse ankete";
                    break
                default:
                    name = "Ankete v teku";
            }
            let sidebar = [
                {label: name, active: true, disabled: true},
                {label: "Nazaj", name: "AdminEntry", active: false},
            ]
            this.$emit("sidebar", sidebar)
        },
        handleScrolling() {
            if(!this.disableScroll) {
                this.pagination += 10;
                if (this.items.length <= this.pagination)
                    this.disableScroll = true;
            }
        }
    },
    computed: {
        itemsFilter() {
            return this.items.slice(0, this.pagination)
        }
    },
    created() {
        this.setSidebar();
        this.load();
    }
}
</script>

<style scoped>

.linkStyleBtn {
    margin-right: 2rem;
}

.selectStyle {
    margin-left: 8px;
}

.tableColName {
    padding-left: 31px;
}

</style>