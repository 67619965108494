<template>
    <div class="sidebarContainer">
        <button v-for="(btn, index) in sidebar" :key="index" class="sidebarButton" :class="{active: btn.active, tabStyle: btn.tab, hideButton: btn.hide}" @click="$emit('openPage', btn)" :disabled="btn.disabled">{{ btn.label }}</button>
    </div>
</template>

<script>
export default {
    name: "Sidebar",
    props: { sidebar: Array }
}
</script>

<style scoped>

.hideButton {
    display: none;
}

.sidebarContainer {
    display: flex;
    flex-direction: column;
    width: 340px;
    min-width: 340px;
}

.sidebarButton {
    color: #fff;
    background: var(--dark-grey);
    text-transform: uppercase;
    border-top-right-radius: 59px;
    border-bottom-right-radius: 59px;
    padding: 23px 32px;
    padding-left: 72px;
    font-size: 19px;
    font-weight: 500;
    margin-bottom: 16px;
}

.sidebarButton.active {
    background: var(--main-color);
}

.sidebarButton.tabStyle {
    width: 88%;
}

.sidebarButton:disabled {
    background: var(--main-color);
    cursor: unset;
}

.sidebarButton:hover {
    transform: scale(1.05);
}

.sidebarButton:disabled:hover {
    transform: none;
}

.sidebarButton:last-child {
    margin-bottom: 0;
}

</style>