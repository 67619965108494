<template>
    <transition name="warning">
        <div v-if="showW" class="warningContainer">
            {{ text }}
        </div>
    </transition>
</template>

<script>

export default {
    name: "Warning",
    data() {
        return {
            showW: false,
            text: ""
        }
    },
    methods: {
        show(text) {
            // klic: this.$root.$children[0].$refs.warningRef.show("");
            this.text = text;
            this.showW = true;
            setTimeout(() => {
                this.showW = false;
            }, 2500);
        }
    }
}
</script>

<style scoped>

.warningContainer {
    position: fixed;
    top: 31%;
    right: 0;
    border-right: none;
    background: var(--main-color);
    color: #fff;
    z-index: 9999;
    border-top-left-radius: 59px;
    border-bottom-left-radius: 59px;
    padding: 23px 32px;
    font-size: 19px;
    font-weight: 500;
}

</style>