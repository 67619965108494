<template>
    <div class="relativeContainer">
        <div v-if="loading" class="loaderContainerAbsolute">
            <div class="loader"></div>
        </div>
        <div v-if="loading" class="loaderOverlay"></div>

        <div class="surveyListTopBar flexElement">
            <input type="text" class="roundedStyle textAreaStyle" placeholder="Išči po anketah" v-model="search" @input="handleSearch">
            <div class="selectByGroup flexElement">
                <button class="linkStyleBtn" @click="openAll">Odpri vse mape</button>
                <div class="surveyListButtons">
                    <button @click="addFolder(true)"><img src="../../assets/img/add_icon_small.svg" alt="Add"><span>Dodaj mapo</span></button>
                </div>
            </div>
        </div>
        <div class="surveyListTable">
            <div class="surveyListTableHeader flexElement">
                <div class="tableCol tableColName" style="width:6%;padding-left:10px">ID</div>
                <div class="tableCol tableColName">Naslov</div>
                <div class="tableCol tableColDate">Zadnji vnos</div>
                <div class="tableCol tableColAuthor">Avtor</div>
                <div class="tableCol tableColAuthor">F/I</div>
                <div class="tableCol tableColActions">Dejanja</div>
            </div>
            <div class="surveyListTableContent">
                <drag-survey-list ref="dragSurveyList" :items="items" @moved="handleMove" @reload="load" @dragged="handleDragged" />
            </div>
        </div>
        <div class="surveyListButtons normalMargin">
            <button @click="addFolder(false)"><img src="../../assets/img/add_icon_small.svg" alt="Add"><span>Dodaj mapo</span></button>
        </div>
    </div>
</template>

<script>

export default {
    name: "SurveyListAll",
    data() {
        return {
            items: [],
            search: null,
            loading: false,
            timer: null,
            drag: false,
        }
    },
    methods: {
        handleSearch() {
            clearTimeout(this.timer);
            this.timer = setTimeout(this.load, 700);
        },
        load() {
            this.loading = true;
            let url = "/folder/index";
            let data = {
                search: this.search,
                searchCols: ["intern_name", "name"]
            }
            api.post(url, data).then(response => {
                // console.log(response)
                this.items = Object.values(response.data.data);
                this.loading = false;
            }).catch(error => {
                console.log(error);
                this.loading = false;
                let errors = Object.keys(this.errorCatching(error).errors);
                this.$root.$children[0].$refs.warningRef.show("Prišlo je do napake. Koda napake: " + errors);
            })
        },
        setSidebar() {
            let sidebar = [
                {label: "Vse ankete", active: true, disabled: true},
                {label: "Nazaj", name:"AdminEntry", active: false },
            ]
            this.$emit("sidebar", sidebar)
        },
        handleMove(value) {
            if (Object.keys(value)[0] == "removed")
                return false;
            
            let element = Object.values(value)[0].element;
            
            let folder = this.getFolderData(element, null, this.items) || {id: -1, items:this.items};

            folder.items.forEach((q, i) => {
                q.order = i + 1;
            });

            let list = folder.items.map(i => {
                return {
                    id: i.id,
                    type: i.items ? "Folder" : "Survey",
                    order: i.order,
                    folder_id: folder.id
                }
            })

            api.post("/folder/reorder", {list: list}).then(response => {
                console.log(response)
            })
        },
        getFolderData(element, folder, items) {
            for (const item of items) {
                if (item.id == element.id && item.items == element.items) {
                    return folder;
                }
                else if (item.items) {
                    let found = this.getFolderData(element, item, item.items);
                    if (found)
                        return found; 
                }
            }
        },
        addFolder(top) {
            this.loading = true;
            let url = "/folder/store";
            let data = {
                name: "Nova mapa",
                order: this.items.length,
            }

            api.post(url, data).then(response => {
                this.load();
                if (top) {
                    window.scrollTo({left: 0, top: document.body.scrollHeight, behavior: "smooth"});
                }
            }).catch(error => {
                console.log(error);
                this.loading = false;
            })
        },
        openAll() {
            this.$refs.dragSurveyList.openAll();
        },
        handleDragged(value) {
            this.$refs.dragSurveyList.dragging(value);
        }
    },
    created() {
        this.setSidebar();
        this.load();
    }
}
</script>

<style scoped>

.linkStyleBtn {
    margin-right: 2rem;
}

.selectStyle {
    margin-left: 8px;
}

.tableColName {
    padding-left: 31px;
}

.surveyListButtons {
    display: flex;
    justify-content: center;
}

.surveyListButtons button {
    display: flex;
    align-items: center;
}

.surveyListButtons button span {
    color: var(--main-color);
    text-transform: none;
    font-size: 18px;
    padding-left: 16px;
}

.loaderContainerAbsolute, .loaderOverlay {
    left: -15px;
    right: -15px;
}

</style>