import TheSurvey from "../views/front/TheSurvey.vue";
import PageHandler from "../views/front/PageHandler.vue";
import QuestionPage from "../views/front/QuestionPage.vue";

export default [
    {
        path: "/anketa",
        name: "Survey",
        component: TheSurvey,
        children: [
            {
                path: ":token",
                name: "PageHandler",
                props: true,
                component: PageHandler,
                children: [
                    {
                        path: "/",
                        name: "QuestionPage",
                        component: QuestionPage,
                    },
                ]
            },
        ]
    },

]