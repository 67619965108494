<template>
    <div :class="marginClass(margin)">
        <textarea v-if="rows > 1" class="textAreaStyle roundedStyle" :placeholder="placeholder" :rows="rows" v-model="newValue" :required="validation" @input="handleInput"></textarea>
        <input v-else type="text" :placeholder="placeholder" class="textAreaStyle roundedStyle" v-model="newValue" :required="validation" @input="handleInput">
        <div v-if="validation && !newValue" class="validationAlert">{{ __("validation.fillTheArea", $store.state.locale) }}</div>
    </div>
</template>

<script>
export default {
    name: "TextInput",
    props: { rows: Number, placeholder: String, margin: {type: String, default: "normal"}, value: String, validation: Boolean, qid: String|Number },
    data() {
        return {
            newValue: this.value || null,
        }
    },
    methods: {
        handleInput() {
            this.$emit("change", {value: this.newValue, question_id: this.qid})
        }
    },
    watch: {
        value: {
            deep: true,
            handler(value) {
                if(value)
                    this.newValue = value;
            }
        }
    }
}
</script>

<style scoped>

</style>