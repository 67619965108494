<template>
    <div class="adminAnswerContainer">
        <div class="flexElement">
            <input type="text" class="roundedStyle textAreaStyle" placeholder="Vpišite besedilo vprašanja" v-model="item.name" @blur="changeName">
        </div>

        <div class="adminAnswersInput">
            <div class="adminAnswersInputSingle">
                <span>Opis najmanše ocene</span>
                <input type="text" class="roundedStyle textAreaStyle" placeholder="npr. Slabo" v-model="item.scale_min">
            </div>
            <div class="adminAnswersInputSingle">
                <span>Opis največje ocene</span>
                <input type="text" class="roundedStyle textAreaStyle" placeholder="npr. Odlično" v-model="item.scale_max">
            </div>
        </div>

        <div class="adminAnswersInput adminAnswersInputSpecial">
            <a-instructors-rating-row v-for="(a,i) in possibleAnswers" :key="i" :index="i" :qIndex="item.index" :item="a" @change="answerInput" :showDelete="possibleAnswers.length > 1" @delete="deletePossibleAnswer(i)" />
            <div class="adminAnswersInputSingle adminAnswersInputSingleSpecial">
                <a-instructors-rating-row :item="{}" :disabled="true" />
                <button @click="addAnswer" class="iconBtn"><img src="../../../assets/img/add_icon_small.svg" alt="Add"></button>
            </div>
        </div>

        <div class="shortRowContainer">
            <create-survey-input :data="inputFileData" @change="handleImage" />
        </div>

        <checkbox-group :options="checkbox" width="100%" :name="item.type+item.index" @change="handleChange" />

        <div class="admConditionContainer">
            <checkbox-group :options="conditionalCheckbox"  width="100%" :name="item.type + item.index" @change="handleConditional" />
            <div class="admConditionalInput normalMargin">
                <div class="admLabel">Vprašanje</div>
                <div class="oneLineInput">
                    <div class="existingText no-wrap">"(ime in priimek)" je z vaše strani prejel/a slabo oceno.</div>
                    <input v-model="item.explain" type="text" class="textAreaStyle roundedStyle">
                </div>
            </div>
            <div class="admConditionalInput">
                <div class="admLabel">Splošno vprašanje</div>
                <input v-model="item.explain_question" type="text" class="textAreaStyle roundedStyle">
            </div>
            <div class="admConditionalInput">
                Splošno vprašanje se pojavi pri vsaj <input v-model="item.limit" type="number" class="textAreaStyle roundedStyle numberInputStyle"> trditvah s slabo oceno.
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AdmInstructorsRating",
    props: {item: Object},
    data() {
        return {
            possibleAnswers: this.item.possible_answers.length > 0 ? this.item.possible_answers : [
                {placeholder: ""},
                {placeholder: ""},
            ],
            inputFileData: {type: "file", name: "Izberi sliko naslovnice", placeholder: "Izberite datoteko"},
            conditionalCheckbox: [{boldLabel: "POGOJ:", label: "Ali želite pridobiti obrazložitev, v primeru ocenitve podane trditve z oceno 1-2? ", checked: this.setBool(this.item.cond)}],
            checkbox: [{boldLabel: "Obvezna ocena:", label: "ocena bo avtomatsko označena.", checked: this.setBool(this.item.mandatory)}],
        }
    },
    methods: {
        addAnswer() {
            this.possibleAnswers.push({placeholder: ""});
        },
        changeName() {
            if (this.item.name)
                this.item.qName = this.item.name;
        },
        answerInput() {
            this.item.possible_answers = this.possibleAnswers;
        },
        handleImage(value) {
            this.item.banner = value;
        },
        deletePossibleAnswer(index) {
            this.possibleAnswers.splice(index, 1);
            this.answerInput();
        },
        handleConditional(value) {
            this.item.cond = !!value.length;
        },
        handleChange(value) {
            this.item.mandatory = !!value.length;
        },
    }
}
</script>

<style scoped>

.adminAnswersInputSpecial {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 1.5rem;
}

.adminAnswersInputSingleSpecial {
    width: unset;
    display: flex;
    align-items: center;
}

</style>