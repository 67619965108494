<template>
    <div class="pageContent normalMargin">
        <div class="multiChoiceContainer" :class="marginClass('auto')">

            <div class="choiceContainer noSelect">
                <input v-model="newValue.value" type="radio" name="multiChoice" id="multiChoice2" :value="__('buttons.yes', $store.state.locale)" @change="handleChange">
                <label for="multiChoice2" class="choiceBox roundedStyle noSelect">{{ __("buttons.yes", $store.state.locale) }}</label>
            </div>
            <div class="choiceContainer noSelect">
                <input v-model="newValue.value" type="radio" name="multiChoice" id="multiChoice1" :value="__('buttons.no', $store.state.locale)" @change="handleChange(true)">
                <label for="multiChoice1" class="choiceBox roundedStyle noSelect">{{ __("buttons.no", $store.state.locale) }}</label>
            </div>
            <div v-if="validation && !newValue.value" class="validationAlert">{{ __("validation.pickOneOption", $store.state.locale) }}</div>
        </div>

        <div v-if="newValue.value == __('buttons.yes', $store.state.locale) && setBool(question.cond)">
            <input
                type="mail"
                class="roundedStyle normalMargin textAreaStyle"
                v-model="newValue.email"
                :placeholder="question.placeholder || __('marks.emailAddress', $store.state.locale)"
            />
            <div v-if="mailValidation" class="validationAlert">{{ __("validation.fillTheArea", $store.state.locale) }}</div>
            <div class="mailingAlert">
                {{ question.note || __("marks.tou", $store.state.locale) }}
            </div>
        </div>

        <div v-else-if="newValue.value == __('buttons.no', $store.state.locale) && setBool(question.explain)">
            <input
                type="text"
                class="roundedStyle normalMargin textAreaStyle"
                v-model="newValue.summary"
                :placeholder="question.explain_question"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: "Mailing",
    props: { question: Object, value: String|Array, validation: Boolean, mailValidation: Boolean },
    data() {
        return {
            newValue: {
                value: null,
                question_id: this.question.id,
            },
        };
    },
    methods: {
        handleChange(removeEmail) {
            if (removeEmail && this.newValue.email)
                delete this.newValue.email;
            this.$emit("change", this.newValue);
        },
    },
    watch: {
        value: {
            deep: true,
            handler(value) {
                if(value) {
                    this.newValue = value;
                }
            }
        }
    }
};
</script>

<style scoped>
.mailingAlert {
    opacity: 0.9;
    text-align: center;
    margin-top: 13px;
    font-size: 13px;
}
</style>