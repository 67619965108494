<template>
    <page-template :noButtons="true">
        <template>
            <div class="contentContainer noButtons">
                <div class="formContainer">
                    <div class="heading">PRIJAVA UPORABNIKA</div>
                    <form action="#" @submit.prevent="submit">
                        <div class="inputDiv roundedStyle textAreaStyle">
                            <span class="iconImg"><img :src="require('../assets/img/user_icon.svg')" alt=""></span>
                            <input type="text" name="email" id="email" class="inputStyle"
                                   v-model="form.email" placeholder="E-mail">
                        </div>
                        <div class="inputDiv roundedStyle textAreaStyle">
                            <span class="iconImg"><img :src="require('../assets/img/lock.svg')" alt=""></span>
                            <input type="password" name="password" id="password" class="inputStyle"
                                   v-model="form.password" placeholder="Geslo">
                        </div>
                        <div v-if="errorMessage" class="validationAlert text-center">{{ errorMessage }}</div>
                        <div>
                            <div class="d-inline-block w-70"><a class="passForgot" style="cursor:pointer" @click="resetPassword">Ste pozabili geslo?</a></div>
                            <div class="d-inline-block w-30 text-right">
                                <button type="submit" class="roundedStyle mainColorBtn">
                                    Prijava
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div v-if="loading" class="loaderContainerAbsolute">
                    <div class="loader"></div>
                </div>
                <div v-if="loading" class="loaderOverlay"></div>
            </div>
        </template>
    </page-template>
</template>

<script>
import {mapActions} from 'vuex'


export default {
    name: 'SignIn',

    data() {
        return {
            form: {
                email: '',
                password: '',
            },
            errorMessage: false,
            loading: false,
        }
    },

    methods: {
        ...mapActions({
            signIn: 'auth/signIn'
        }),
        resetPassword(){
            this.$router.replace({name: 'Pozabljeno geslo'})
        },
        async submit() {
            this.errorMessage = false;
            this.loading = true;
            await this.signIn(this.form).catch(error => {
                let errors = this.errorCatching(error);
                let first = Object.keys(errors.errors)[0];
                this.errorMessage = errors.errors[first][0];
                this.loading = false;
            })
            this.$router.replace({name: 'AdminEntry'})
        }
    },
    watch: {
        errorMessage: {
            deep:true,
            handler(value, oldValue) {
                console.log(value, " ---- ", oldValue)
            }
        }
    }
}
</script>

<style scoped>
.contentContainer {
    position: relative;
}

.passForgot{
    margin-left: 32px;
    color: var(--main-color);
    text-decoration: none;
    font-size: 17px;
}

.text-right {
    text-align: right;

}

.w-70 {
    width: 70% !important;
    margin: 0 !important;
    padding: 0 !important;
    display: inline-block;
}
.w-30 {
    width: 30% !important;
    margin: 0 !important;
    padding: 0 !important;
    display: inline-block;
}

.validationAlert {
    margin-left: 32px;
    font-size: 17px;
    margin-bottom: 10px;
}

.iconImg {
    left: -25px;
    position: relative;
    top: 4px;
}

.inputStyle {
    width: 340px;
    margin-right: 20px;
    border: none;
    color: #707070;
    height: 100%;
    font-size: 16px;
    background-color: transparent;
}

::placeholder {
    color: #707070;
    opacity: 1;
    font-size: 16px;
    background-color: transparent;

}

.heading {
    font-family: "Open Sans", monospace;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: black;
    padding: 20px 30px;
    margin-bottom: 40px;
}

.formContainer {
    height: fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: 7%;
}

.inputDiv {
    width: 420px;
    height: 44px;
    margin: 14px;
    padding: 0;
    text-align: right;
    border-radius: 59px;
    border: solid 1px #707070;
}

label {
    font-size: 14px;
}

.buttonDiv {
    display: flex;
    justify-content: flex-end;
}

button {
    width: 130px;
}
</style>