<template>
    <div class="adminAnswerContainer">
        <div class="flexElement">
            <input type="text" class="roundedStyle textAreaStyle" placeholder="Vpišite besedilo vprašanja" v-model="item.name" @blur="changeName">
        </div>

        <div class="adminAnswerMargin">
            <input type="text" class="roundedStyle textAreaStyle" placeholder="Vpišite prikazno besedilo v vnosnem polju" v-model="item.placeholder">
        </div>

        <div class="shortRowContainer">
            <create-survey-input :data="inputFileData" :oldValue="item.banner"  @change="handleImage" />
        </div>

        <checkbox-group :options="checkbox" width="100%" :name="item.type + item.index" @change="handleChange" />
    </div>
</template>

<script>
export default {
    name: "AdmTextAnswer",
    props: {item: Object},
    data() {
        return {
            inputFileData: {type: "file", name: "Izberi sliko naslovnice", placeholder: "Izberite datoteko"},
            checkbox: [{boldLabel: "Obvezen odgovor:", label: "anketirancu bo onemogočeno nadaljevanje, dokler ne odgovori na vprašanje.", checked: this.setBool(this.item.mandatory)}]
        }
    },
    methods: {
        handleChange(value) {
            this.item.mandatory = !!value.length;
        },
        changeName() {
            if (this.item.name)
                this.item.qName = this.item.name;
        },
        handleImage(value) {
            this.item.banner = value;
        }
    }
}
</script>

<style scoped>

.textareaHeight {
    height: 140px !important;
    border-radius: 22px !important;
}

</style>