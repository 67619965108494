<template>
    <div class="dashboardContainer">
        <h2 class="dashboardTitle">Dobrodošli</h2>
        <p class="dashboardText">V primeru težav pošljite mail na <a class="linkStyleBtn" href="mailto:support@wpm.si">support@wpm.si</a>. Prosimo vas, da priložite tudi priponko napake.</p>
    </div>
</template>

<script>
export default {
    name: "AdminEntry",
    data() {
        return {
            sidebar: [
                // {label: "Ankete", name: "SurveyListAll", active: true },
                {label: "Ankete", name: "Surveys", active: true },
                // {label: "Ustvari novo anketo", name: "CreateSurvey", active: true },
                // {label: "Moje ankete", name: "SurveyList", params: {filter: "my-surveys"}, active: false },
                // {label: "Ankete v teku", name: "SurveyList", params: {filter: "active-surveys"}, active: false },
                {label: "Nastavitve", name:"Settings", active: false },
            ],
        }
    },
    created() {
        this.$emit("sidebar", this.sidebar)
    }
}
</script>

<style scoped>

.dashboardContainer {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

.dashboardTitle {
    text-transform: uppercase;
    font-weight: 500;
    text-align: center;
    margin-bottom: 1.5rem;
}

</style>