<template>
    <div :class="marginClass(margin)">
        <div v-if="lowGrade.length < question.limit">
            <div v-for="(a,i) in value" :key="i+'feedbacks'" class="feedbackContainer">
                <div v-if="parseInt(a.value) < 3">
                    <div v-if="question.question_type_id == 4" class="question">{{  __("marks.claim", $store.state.locale ) }} "{{ fName(a.possible_answer_id) }}" {{__("marks.badGrade", $store.state.locale )}}. {{ question.explain }}</div>
                    <div v-else class="question">"{{ fName(a.possible_answer_id) }}" {{  __("marks.personGrade", $store.state.locale ) }} {{ question.explain }}</div>
                    <textarea class="textAreaStyle roundedStyle" :placeholder="placeholder" :rows="rows" v-model="a.summary" :required="validation"></textarea>
                </div>
            </div>
            <div v-if="validation && !newValue" class="validationAlert">{{ __("validation.fillTheArea", $store.state.locale) }}</div>
        </div>
        <div v-else>
            <div class="question">{{ question.explain_question }}</div>
            <textarea class="textAreaStyle roundedStyle" v-model="newValue" :placeholder="placeholder" :rows="rows" :required="validation" @input="handleInput"></textarea>
            <div v-if="validation && !newValue" class="validationAlert">{{ __("validation.fillTheArea", $store.state.locale) }}</div>
        </div>
        
    </div>
</template>

<script>
export default {
    name: "FeedbackInput",
    props: { rows: Number, placeholder: String, margin: {type: String, default: "normal"}, value: Array, validation: Boolean, question: Object, lowGrade: Array },
    data() {
        return {
            newValue: null,
        }
    },
    methods: {
        handleInput() {
            this.value.forEach(v => {
                if (parseInt(v.value) < 3)
                    v.summary = this.newValue;
            });
        },
        fName(paid) {
            let pa = this.question.possible_answers.find(a => paid == a.id);
            return pa.placeholder;
        }
    },
    watch: {
        value: {
            deep: true,
            immediate: true,
            handler(value) {
                if(value) {
                    let test = value.find(a => a.summary);
                    if (test) {
                        test = test.summary;
                        let filter = value.filter(a => a.summary == test);
                        if (filter.length == this.lowGrade.length)
                            this.newValue = test;
                    }
                    
                }
            }
        }
    }
}
</script>

<style scoped>
.question {
  padding-bottom: 15px;
  font-weight: 600;
}

.feedbackContainer {
    margin-bottom: 50px;
}
</style>