<template>
    <div class="surveyContainer">

        <div class="inputRow">
            <div class="uppercaseTitle">Nastavitve ankete</div>
        </div>

        <div class="inputRow">
            <div class="inputName inputCol">Status ankete</div>
            <div class="inputCol">
                <div class="textAreaStyle roundedStyle strongerText">{{ status }}</div>
            </div>
        </div>

        <div  v-if="qrCode && survey.status != 'finished'" class="inputRow noHeight qrCodeContainer">
            <div class="inputCol">
                <div class="inputName">QR koda:</div>
                <div class="inputColContent">
                    <img class="qrCodeImg" :src="qrCode" alt="QR koda">
                </div>
            </div>
            <div class="inputCol">
                <div class="inputName">Prenesi QR kodo:</div>
                <div class="inputColContent">
                    <button class="roundedStyle noColorBtn" @click="downloadQR('svg')">
                        <svg width="24" height="24" viewBox="0 0 24 24">
                            <path d="M5 18h14v-4.5a.5.5 0 1 1 1 0v5a.498.498 0 0 1-.5.5h-15a.5.5 0 0 1-.5-.5v-5a.5.5 0 1 1 1 0V18zm6.5-3.625V5.5a.5.5 0 1 1 1 0v8.875l2.654-2.543a.511.511 0 0 1 .7 0 .46.46 0 0 1 0 .671l-3.504 3.358a.511.511 0 0 1-.7 0l-3.505-3.358a.46.46 0 0 1 0-.671.511.511 0 0 1 .701 0l2.654 2.543z"></path>
                        </svg>
                        <span>SHRANI QR KODO (.SVG)</span>
                    </button>
                    <button class="roundedStyle noColorBtn" @click="downloadQR('png')">
                        <svg width="24" height="24" viewBox="0 0 24 24">
                            <path  d="M5 18h14v-4.5a.5.5 0 1 1 1 0v5a.498.498 0 0 1-.5.5h-15a.5.5 0 0 1-.5-.5v-5a.5.5 0 1 1 1 0V18zm6.5-3.625V5.5a.5.5 0 1 1 1 0v8.875l2.654-2.543a.511.511 0 0 1 .7 0 .46.46 0 0 1 0 .671l-3.504 3.358a.511.511 0 0 1-.7 0l-3.505-3.358a.46.46 0 0 1 0-.671.511.511 0 0 1 .701 0l2.654 2.543z"></path>
                        </svg>
                        <span>SHRANI QR KODO (.PNG)</span>
                    </button>
                    <button class="roundedStyle noColorBtn" @click="downloadQR('eps')">
                        <svg width="24" height="24" viewBox="0 0 24 24">
                            <path d="M5 18h14v-4.5a.5.5 0 1 1 1 0v5a.498.498 0 0 1-.5.5h-15a.5.5 0 0 1-.5-.5v-5a.5.5 0 1 1 1 0V18zm6.5-3.625V5.5a.5.5 0 1 1 1 0v8.875l2.654-2.543a.511.511 0 0 1 .7 0 .46.46 0 0 1 0 .671l-3.504 3.358a.511.511 0 0 1-.7 0l-3.505-3.358a.46.46 0 0 1 0-.671.511.511 0 0 1 .701 0l2.654 2.543z"></path>
                        </svg>
                        <span>SHRANI QR KODO (.EPS)</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="divider"></div>

        <create-survey-input v-if="survey.status === 'draft'" :data="publishStart" :oldValue="survey.published_from" @change="handleChange(...arguments, 'published_from')"/>

        <div v-else-if="survey.published_from" class="inputRow">
            <div class="inputName inputCol">Status ankete</div>
            <div class="inputCol">
                <div class="textAreaStyle roundedStyle">{{ survey.published_from }}</div>
            </div>
        </div>

        <create-survey-input v-if="survey.status != 'finished'" :key="publishEnd.min + 'fromKey'" :data="publishEnd" :oldValue="survey.published_to" @change="handleChange(...arguments, 'published_to')"/>

        <div v-else-if="survey.published_to" class="inputRow">
            <div class="inputName inputCol">Datum začetka objave</div>
            <div class="inputCol">
                <div class="textAreaStyle roundedStyle">{{ survey.published_from }}</div>
            </div>
        </div>

        <div class="inputRow">
            <div class="inputCol"></div>
            <div class="inputCol">
                <button v-if="survey.status == 'draft'" class="roundedStyle mainColorBtn surveyBtn" @click="$emit('publish')">OBJAVI TAKOJ</button>
                <button v-else-if="survey.status == 'published'" class="roundedStyle mainColorBtn surveyBtn" @click="$emit('finish')">ZAKLJUČI TAKOJ</button>
            </div>
        </div>
        
        <div v-if="link" class="inputRow">
            <div class="inputName inputCol">Povezava do ankete</div>
            <div class="inputCol"><a :href="link" target="_blank">{{ link }}</a></div>
        </div>

        <div  class="inputRow">
            <div class="inputName inputCol">Predogled ankete</div>
            <div class="inputCol"><a :href="preview" target="_blank">{{ preview }}</a></div>
        </div>

        <checkbox-group v-if="survey.status === 'draft'" class="marginDivider" :options="checkboxMail" name="mail-checkbox" @change="checkMail" />

        <transition name="else" mode="out-in">
            <div v-if="survey.type === 'mail'" class="greyContainer">
                <div key="typeKey1">
                    <create-survey-input v-for="(d, i) in mailData" :key="'mailData'+i+mailData[mailData.length-1].min" :data="d" :oldValue="getMailContent(d.mailType, d.key, d.index)" @change="handleMail(...arguments, d.key, d.mailType, d.index)"/>
                </div>
                <div v-if="survey.status != 'draft'">
                    <div class="inputRow">
                        <div class="strongerText">Udeleženci</div>
                    </div>
                    <div class="inputRow noHeight">
                        <div class="inputCol"></div>
                        <div class="inputCol">
                            <mail-table :data="survey.mailed" />
                        </div>
                    </div>
                </div>
            </div>
        </transition>

    </div>
</template>

<script>
export default {
    name: "SurveyStep3",
    props: { survey: Object, qrCode: String, link: String },
    data() {
        return {
            publishStart: {
                type: "datetime",
                name: "Datum začetka objave",
                placeholder: "Klikni za izbiro datuma",
                key: "published_from",
                min: this.getTomorrow(),
            },
            publishEnd: {
                type: "datetime",
                name: "Datum zaključka objave",
                placeholder: "Klikni za izbiro datuma",
                key: "published_to",
                min: this.getTomorrow(),
            },
            mailData: [
                {title: "Prejemniki", hide: this.survey.status != "draft"},
                {type: "mailGroup", name: "Dodaj prejemnike", key: "mailed", hide: this.survey.status != "draft"},
                {title: "Vabilo"},
                {type: "text", name: "Zadeva", mailType: "invite", key: "subject", disabled: this.survey.status != "draft"},
                {type: "textarea", rows: 14, name: "Sporočilo", mailType: "invite", key: "content", disabled: this.survey.status != "draft"},
                {title: "Opomnik"},
                {type: "text", name: "Zadeva", mailType: "remind", key: "subject",  disabled: this.survey.status === "finished"},
                {type: "textarea", name: "Sporočilo", rows: 14, mailType: "remind", key: "content", disabled: this.survey.status === "finished"},
                {type: "datetime", name: "Čas pošiljanja", placeholder: "Klikni za izbiro datuma", key: "execution_times", mailType: "remind", index: 0, hide: this.survey.status === "finished"},
                // {type: "text", name: "Zahvala zadeva", mailType: "thank", key: "subject"},
                // {type: "textarea", name: "Zahvala sporočilo", mailType: "thank", key: "content"},
            ],
            checkboxMail: [
                {boldLabel: "Pošlji povezavo do ankete po elektronski pošti", checked: this.survey.type == "mail"}
            ],
            preview: this.prependUrlFront("preview/"+this.survey.id)
        }
    },
    methods: {
        loadQR() {
            let url = "/token/" + this.survey.token + "/qr-code/base64";

            api.post(url).then(response => {
                this.$emit("saveQR", response.data.data);
            })
        },
        downloadQR(type) {
            let url = "/token/" + this.survey.token + "/qr-code/download-" + type;

            let a = document.createElement('a');
            a.href = process.env.VUE_APP_API_URL + url;
            console.log(a.href);
            a.click();
            a.remove();
        },
        handleChange(value, key) {
            this.survey[key] = value;
        },
        checkMail(value) {
            this.survey.type = !!value.length ? "mail" : "qrcode";
            if (this.survey.type === "qrcode") {
                this.survey.mailed = [];
                this.survey.mailcontents = [];
            } else {
                this.mailContentHandler();
            }
        },
        handleMail(value, key, type, index) {
            if (key == "mailed") {
                this.survey.mailed = value;
            } else {
                let exists = this.survey.mailcontents.find(a => a.type == type);
                if (exists) {
                    if (key == "execution_times") {
                        exists[key][index].execute = value;
                    } else {
                        exists[key] = value; 
                    }
                } else {
                    let tmp = {type: type, subject: null, content: null};
                    tmp[key] = value;
                    this.survey.mailcontents.push(tmp);

                }
            }
        },
        getMailContent(type, key, index) {
            if (key == "content" || key == "subject") {
                if (type == "remind" && this.survey.mailcontents.length < 2)
                    return null;
                return this.survey.mailcontents.find(a => a.type == type)[key];
            } else if (key == "mailed") {
                return this.survey.mailed;
            } else if (key =="execution_times") {
                if (this.survey.mailcontents.length > 1)
                    return this.survey.mailcontents[1].execution_times[index].execute;
            }
        },
        mailContentHandler() {
            if (this.survey.mailcontents.length < 1)
                this.fillMailContent();
            else if (this.survey.mailcontents.length > 1 && this.survey.mailcontents[1].execution_times.length == 0)
                this.survey.mailcontents[1].execution_times = [{execute: null}];
            this.repairMailContent();
        },
        fillMailContent() {
            let inviteContent = this.__("mail.invite", this.survey.locale);
            let remindContent = this.__("mail.remind", this.survey.locale);
            this.survey.mailcontents = [
                {type: "invite", subject: "", content: inviteContent},
                {type: "remind", subject: "", content: remindContent, execution_times: [{execute: null}]},
            ]
        },
        repairMailContent() {
            let regex = /id='(.*)'/;
            let newString = "id='" + this.survey.id + "'";
            this.survey.mailcontents.forEach(c => {
                c.content = c.content.replace(regex, newString);
            })
        }
    },
    computed: {
        status() {
            if (this.survey.status === "draft")
                return "osnutek";
            else if (this.survey.status === "published")
                return "objavljena";
            else if (this.survey.status === "finished")
                return "zaključena"
        }
    },
    created() {
        this.mailContentHandler();

        if (!this.qrCode)
            this.loadQR();
    },
    watch: {
        "survey.published_from": {
            deep: true,
            handler(value) {
                this.publishEnd.min = value;
                this.mailData[this.mailData.length-1].min = value;
            }
        }
    }
}
</script>

<style scoped>
.inputColContent {
    margin-top: 20px
}

svg {
    height: 34px;
    width: 34px;
    fill: var(--dark-grey)
}

.noColorBtn {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 20px;
    padding: 4px 12px;
    font-weight: 500;
}

.noColorBtn img {
    height: 34px;
}

.noColorBtn:last-child {
    margin-bottom: 0;
}


.noColorBtn:hover svg {
    fill: #fff;
}

.qrCodeImg {
    height: 186px;
    width: 186px;
}

.qrCodeContainer {
    margin-top: 70px !important;
}

.marginDivider {
    margin-top: 4rem;
    margin-bottom: 2rem;
}

.surveyBtn {
    width: 100%;
}

.surveyBtn:hover {
    transform: scale(1.02);
}

</style>