<template>
    <header>
        <div style="width:100%;">
            <div style="width:100%;text-align:center;">
                <img :src="logo" class="logoImg">
            </div>
            <div class="headerSection pageTitle noSelect">
                {{ name }}
            </div>
        </div>
        <div v-if="$store.state.auth.user && admin" class="headerSection headerActions">
            <div >

                <div class="headerModal" @click="openModal">
                    <div class="headerModalElement flexElement">
                        <img src="../assets/img/avatar.svg">
                        <span class="headerName">{{ $store.state.auth.user.name }}</span>
                        <div class="headerArrow"></div>
                    </div>
                    <header-modal v-if="opened" @logout="logout" @changepass="changePass" @close="opened = false"/>
                </div>
            </div>
        </div>

    </header>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
    name: "CustomHeader",
    props: {admin: Boolean, name: String, logo: String},
    data() {
        return {
            opened: false,
        };
    },
    methods: {
        ...mapActions({
            signOut: 'auth/signOut'
        }),
        ...mapGetters({
            authenticated: 'auth/authenticated'
        }),

        async logout() {
            this.opened = false;
            await this.signOut()
            this.$router.push({name: 'Prijava'})
        },
        openModal() {
            this.opened = true;
        },
        async changePass() {
            const confirm = await this.$parent.$refs.confirmDialogueHeader.open();
            if (confirm) {
                let url = "/send-reset-email";
                let data = {email: this.$store.state.auth.user.email}
                api.post(url, data).then(response => {
                    console.log("reset mail", response);
                })
            }
        }
    },
}
</script>

<style scoped>

header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    display: flex;
    color: #fff;
}

.logoImg {
    max-width:80%;
    max-height:8vh;
    margin-bottom:20px;
}

.headerSection {
    display: block;
    text-align: center;
}

.pageTitle {
    text-align: center;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
}

.headerActions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 20px;
}

.headerArrow {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 8px solid #fff;
}

.headerModalElement {
    cursor: pointer;
}

.headerName {
    margin-left: 10px;
    margin-right: 25px;
    font-size: 16px;
}

@media (min-width: 801px) {
    header, .logoImg {
        height: 88px;
    }

    .pageTitle {
        font-size: 21px;
        font-weight: 600;
        white-space: nowrap;
    }
}

</style>