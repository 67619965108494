import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import trans from './translations'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        auth,
        trans
    },
    state: {
        locale: null
    },
    mutations: {
        changeLocale(state, n) {
            state.locale = n;
        }
    }
})