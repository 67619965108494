<template>
    <div class="surveyContainer">
        <div class="inputRow">
            <div class="uppercaseTitle">Vpiši osnovne značilnosti nove ankete</div>
        </div>

        <create-survey-input v-for="(d, i) in surveyData" :key="'surveyData'+i" :data="d" @change="handleChange(...arguments, d.key)"/>

        <div class="inputRow">
            <button class="roundedStyle mainColorBtn nextButton" @click="createSurvey">USTVARI ANKETO
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "CreateSurvey",
    data() {
        return {
            survey: {
                user_id: this.$store.state.auth.user.id,
                customer_id: this.$store.state.auth.user.customer_id,
                locale: "sl",
                status: "draft",
                connections: [],
            },
            sidebar: [
                {label: "Ustvari novo anketo", active: true, disabled: true},
                {label: "Nazaj", name: "AdminEntry", active: false},
            ],
            surveyData: [
                {
                    type: "text",
                    name: "Interno ime ankete",
                    placeholder: "Vpišite interno ime ankete",
                    key: "intern_name",
                    required: false
                },
                {
                    type: "text",
                    name: "Prikazano ime ankete (zgoraj)",
                    placeholder: "npr. LEADERSHIP ACADEMY",
                    key: "name",
                    required: false
                },
                {
                    type: "checkbox",
                    name: "Jezik",
                    radio: true,
                    options: [{label: "SLO", value: "sl", checked: true}, {
                        label: "ANG",
                        value: "en",
                    }],
                    key: "locale"
                },
            ],
        }
    },
    methods: {
        handleChange(value, key) {
            this.survey[key] = value;
            if (key == "type")
                this.survey.type = value
        },
        createSurvey() {
            this.surveyData.forEach(d => {
                if ("required" in d) {
                    d.required = true;
                }
            });

            let url = "/survey/store";

            api.post(url, this.survey).then(response => {
                this.$router.push({name: "EditSurvey", params: {id: response.data.data.id}});
            }).catch(error => {
                let errors = Object.keys(this.errorCatching(error).errors);
                if (errors.includes("intern_name")) 
                    this.$root.$children[0].$refs.warningRef.show("Anketa s tem internim imenom že obstaja. Prosimo vpišite drugo interno ime.");
                else
                    this.$root.$children[0].$refs.warningRef.show("Prišlo je do napake. Koda napake: " + errors);
            })
        },
    },
    created() {
        this.$emit("sidebar", this.sidebar);
    }

}
</script>

<style scoped>

</style>