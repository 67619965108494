<template>
    <div class="draggableItemContainer">
        <button class="draggableItemHeader roundedStyle" :class="item.type != 'review' ? 'draggableIcon handle' : ''" @click="handleOpen">
            {{ item.qName }}  <span>({{ item.typeDescription}})</span>
            <div class="iconBtns">
                <button v-if="item.type != 'review'" class="iconBtn" @click.stop="duplicateQ" title="Podvoji">
                    <img src="../../assets/img/duplicate_icon.svg" alt="Duplicate">
                </button>
                <button class="iconBtn" @click.stop="deleteQ" title="Izbriši">
                    <img src="../../assets/img/trash_icon.svg" alt="Delete">
                </button>
            </div>
        </button>
        <transition name="fade">
            <div v-if="open">
                <a-check-answer v-if="item.type === 'radiobox' || item.type === 'checkbox'" :item="item" />
                <a-yes-no-answer v-else-if="item.type === 'yesno'" :item="item" />
                <a-scale-rating v-else-if="item.type === 'scale'" :item="item" />
                <a-text-answer v-else-if="item.type === 'text' || item.type === 'longtext'" :item="item" />
                <a-instructors-rating v-else-if="item.type === 'scaleimage'" :item="item" />
                <a-review v-else-if="item.type === 'review'" :item="item" />
                <div v-else class="adminAnswerContainer">Prišlo je do napake</div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: "DraggableItem",
    props: { item: Object, opened: Boolean },
    data() {
        return {
            open: this.opened,
        }
    },
    methods: {
        deleteQ() {
            this.$emit('delete', this.item.index);
        },
        duplicateQ() {
            this.$emit("duplicate", this.item);
        },
        handleOpen() {
            this.open = !this.open;
            this.$emit("openChange");
        }
    },
}
</script>

<style scoped>

.draggableItemHeader {
    background-color: var(--light-grey);
    border: solid 1px var(--light-grey);
    color: var(--dark-grey);
    text-align: left;
    padding: 12px 30px;
    font-size: 18px;
    width: 100%;
    text-transform: none;
    position: relative;
}

.draggableItemHeader.draggableIcon {
    background-image: url("../../assets/img/drag_icon.svg");
    background-repeat: no-repeat;
    background-position: 97% 50%;
}

.draggableItemHeader span {
    font-weight: normal;
}

.draggableItemContainer {
    width: 100%;
    margin: 30px 0;
}

.iconBtns {
    position: absolute;
    right: 5%;
    top: 50%;
    transform: translate(0, -50%);
}
/* 
.iconBtn {
    padding: 0;
    margin-right: 20px;
} */

.iconBtn:last-child {
    margin-right: 10px;
}

.iconBtn img {
    width: 34px;
    height: 34px;
}

</style>