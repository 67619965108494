export default {
    namespaced: true,

    mutations: {
        SET_TRANSLATIONS(state, value) {
            localStorage.setItem("translations", JSON.stringify(value));
        },
    },
    actions: {
        loadTranslations({commit}) {
                return api.post('/get-translations').then((response) => {
                    commit('SET_TRANSLATIONS', response.data.data)
                }).catch(() => {
                    commit('SET_TRANSLATIONS', null)
                });
        }
    }

}
