<template>
    <transition name="router" mode="out-in">
        <rating v-if="step == 1" :validation="validation" :ratings="question.ratings" :value="value" :qid="question.id" @change="handleChange" />
        <rating-yes-no :summary="question.summary" v-else-if="step == 2" @next="handleStep2" />
        <rating-review :summary="question.summary" :validation="validation" v-else-if="step == 3" @change="handleReview" />
    </transition>
</template>

<script>
export default {
    name: "RatingHandler",
    props: { validation: Boolean, step: Number, question: Object, value: Object  },
    methods: {
        handleChange(value) {
            this.$emit("change", value);
        },
        handleStep2(yes) {
            this.$emit("next", yes);
        },
        handleReview(value) {
            this.value.testimonial = value;
        }
    }
}
</script>

<style>

</style>