<template>
    <div class="roundedStyle textAreaStyle mailGroupBox">
        <div v-for="(d, i) in data" :key="'mailTable' + i" class="mailTableData">{{ d.email }}</div>
    </div>
</template>

<script>
export default {
    name: "MailTable",
    props: { data: Array },
}
</script>

<style scoped>

.mailTableData {
    font-weight: 600;
    padding-bottom: 8px;
}

.mailTableData:last-child {
    padding-bottom: 0;
}

</style>