<template>
    <div class="vprasanje-container lestvica-predavateljev" v-if="testimonials.length>0">
        <span class="vprasanje komentar-naslov">TESTIMONIALS</span>

        <table class="tabela-mnenja">
            <tr>
                <th style="text-align: center" v-for="(o,i) in headers" :key="i+'h'">{{ o.column_name }}</th>
            </tr>

            <tr v-for="(t,i) in testimonials" :key="i+'t'">
                <td>
                    <div class="oseba">
                        <img alt="Oseba" :src="prependUrl(t.image)">
                    </div>
                </td>
                <td v-for="(v,c) in t.values" :key="c+'v'" style="text-align: center">{{ v.value }}</td>
                <td>
                    <div class="ocena-container">
                        <img v-for="z in parseInt(t.stars)" class="ocena-zvezdica" alt="Zvezdica"
                             src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADkAAAA2CAMAAABKkMFiAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAA2UExURUdwTMYJO8gLPMcKPcYKPMcKPsYJO8YJPNcfSMYJPMwMQMYJO8YJO8cKO8cNP8YKPMUJPMUJO0aEhGcAAAARdFJOUwCKLld8Oe2nCLcU3MiZJmFuIE/TkgAAATNJREFUSMfFluuSgyAMhbknQdHy/i+7tlutUgyQmZ09P3U+DjlgolKMnBLKZy8kKZPUMgtNaSNJaikzpRdJUkuJKb3JYVOTd5lBEg4SpJajpnAiQWo5ZgoXcsB0yVctQssu0+itgznkUmEGZ32sIWjqSGUBg0dRLjWRcoHknqUj5XHRy1iA0nvHwygdpSIMgYCnaEEIVg6fAcsTTZ1g+r4MSQoqpTtAXb+1Wgq2Uc2MPRZkh6KTgl/doL8zWIa06m92CyPX7qKJIScORPZUsLu3lzLCaPlwtfTyqaIhFXlRb7SzV37uDPcc7fRblZ26wvUfbj0erh/2/j9l3dv/47w6PvbhsTaiDa7cFbrQCPcZbdC1YRd1YMPd3qZ4N1q33hhu524G7utdIN8u2/oJMVH9r34ASnpO00Y4CiUAAAAASUVORK5CYII=">
                    </div>
                    <p>{{ t.review }}</p></td>
            </tr>
        </table>
    </div>
</template>

<script>

export default {
    name: "AnlTestimonials",
    props: {headers: Object | Array, testimonials: Object | Array},
}

</script>

<style scoped>

.vprasanje-container {
    page-break-inside: avoid;
}

.komentar-naslov {
    margin-top: 60px;
}

.vprasanje {
    font-weight: 600;
    font-size: 14px;
    display: block;
}

table {
    width: 100%;
    margin-top: 30px;
    margin-left: -2px;
    font-size: 14px;
}

.tabela-mnenja th, .tabela-mnenja td {
    width: 800px;
    vertical-align: top;
}

.tabela-mnenja td:nth-child(1), .tabela-mnenja th:nth-child(1) {
    text-align: center;
}

.tabela-mnenja td:last-child {
    text-align: center;
}

.tabela-mnenja td:nth-child(2) {
    text-align: center;
}

.tabela-mnenja td:nth-child(3) {
    text-align: center;
}

th {
    padding-bottom: 10px;
    margin-bottom: 10px;
    font-weight: bold;
    text-transform: uppercase;
    border-bottom: 1px solid black;
}

.tabela-mnenja td {
    padding-bottom: 50px;
    font-size: 13px;
}

.tabela-mnenja p {
    margin: 0;
    background-color: #e6e6e6;
    padding: 10px;
    border-radius: 4px;
}

.oseba {
    text-align: center;
    margin: auto;
}

.oseba img {
    width: 75px;
    height: auto;
    border-radius: 270%;
}

.oseba span {
    display: block;
    padding-top: 10px;
}

span.povezava a {
    color: #c5093b;
    text-decoration: none;
    font-weight: bold;
}

span.povezava {
    padding-top: 5px !important;
}

.ocena-zvezdica {
    width: 30px;
    height: auto;
}

</style>