<template>
    <page-template :noButtons="true">
        <template>
            <div class="contentContainer noButtons">
                <div class="formContainer">
                    <div class="heading">OBNOVITEV GESLA</div>
                    <div class="subheading">Vpišite vaš elektronski naslov s katerim ste prijavljeni in poslali vam bomo
                        povezavo za obnovitev gesla
                    </div>
                    <form action="#" @submit.prevent="sendApprovalLink">
                        <div class="inputDiv roundedStyle textAreaStyle">
                            <span class="iconImg"><img :src="require('../assets/img/user_icon.svg')" alt=""></span>
                            <input type="text" name="email" id="email" class="inputStyle"
                                   v-model="email" placeholder="E-mail">
                        </div>
                        <!-- <div class="validationAlert">Ta e-mail ni registriran v našem sistemu.</div> -->
                        <div class="approveBtn">
                            <button type="submit" class="roundedStyle mainColorBtn">
                                Potrdi
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <div v-if="loading" class="loaderContainerAbsolute">
                <div class="loader"></div>
            </div>
            <div v-if="loading" class="loaderOverlay"></div>
        </template>
    </page-template>
</template>

<script>


export default {
    name: 'PasswordReset',

    data() {
        return {
            email: '',
            loading: false,
        }
    },

    methods: {
        sendApprovalLink() {
            this.loading = true;
            let url = "/send-reset-email";
            let data = {
                email: this.email,
            };
            api.post(url, data).then(response => {
                setTimeout(() => {
                    this.$router.push({"name": "Prijava"});
                }, 1000);
            }).catch(error => {
                this.loading = false;
                let errors = this.errorCatching(error);
                let firstError = errors.errors[Object.keys(errors.errors)[0]][0];
                this.$root.$children[0].$refs.warningRef.show(firstError);
            });
        }
    }
}
</script>

<style scoped>


.approveBtn {
    text-align: center;
    margin-top: 25px;
}

.validationAlert {
    font-size: 17px;
    color: #707070;
    margin-bottom: 10px;
    text-align: center;
}

.failed {
    color: red;
}

.iconImg {
    left: -25px;
    position: relative;
    top: 4px;
}

.inputStyle {
    width: 340px;
    margin-right: 20px;
    border: none;
    color: #707070;
    height: 100%;
    font-size: 16px;
    background-color: transparent;
}

::placeholder {
    color: #707070;
    opacity: 1;
    font-size: 16px;
    background-color: transparent;

}

.heading {
    font-family: "Open Sans", monospace;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: black;
    padding: 20px 30px;
    margin-bottom: 40px;
}

.subheading {
    max-width: 420px;
    font-size: 16px;
    font-family: "Open Sans", monospace;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #707070;
    margin-bottom: 25px;
}

.formContainer {
    height: fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: 7%;
}

.inputDiv {
    width: 420px;
    height: 44px;
    margin: 14px;
    padding: 0;
    text-align: right;
    border-radius: 59px;
    border: solid 1px #707070;
}

label {
    font-size: 14px;
}


button {
    width: 200px;
}
</style>