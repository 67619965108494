<template>
    <div class="vprasanje-container kratek-besedilni-odgovor">
        <span class="st-vprasanja">{{ question.order }}. {{ __("analysis.question", locale) }}</span>
        <span class="vprasanje">{{ question.name }}</span>
        <l-text-answer-row v-for="(answer, i) in dataAnalysis" :answer="answer" :question="question" :key="i" @reload="load" />

        <confirm-dialogue v-if="question.special_type == 'MBA'" ref="confirmDialogue" :okBtn="confirmData.okBtn" :cancelBtn="confirmData.cancelBtn" :message="confirmData.message"></confirm-dialogue>
    </div>
</template>

<script>

export default {
    name: "AnlTextAnswer",
    props: {locale:String, question:Object},
    data() {
        return {
            dataAnalysis: null,
            confirmData: {
                okBtn: "Potrdi",
                cancelBtn: "Prekliči",
                message: "Ali ste prepričani, da želite izbrisati ta odgovor?",
            },
        }
    },
    methods: {
        load(){
            let url = "/analysis/query-answer/"+this.question.id;
            api.post(url).then(response => {
                this.dataAnalysis = response.data.data;
            }).finally(() => {
                this.$emit("loaded");
            });
        },
    },
    created(){
        this.load();
    },
}

</script>

<style scoped>

.vprasanje-container{
    page-break-inside: avoid;
}

.st-vprasanja{
    margin-top: 40px;
    display: block;
    font-size: 14px;
    font-weight: 300;
}

.vprasanje{
    font-weight: 600;
    font-size: 14px;
    display: block;
}


</style>