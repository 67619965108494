<template>
    <div class="checkboxGroupContainer" :class="{columnContainer: column}">
        <label v-for="(o,i) in options" :key="i" class="singleCheckboxContainer"  :style="'width:'+width">
            <span v-if="!unclickableLabel"><b>{{o.boldLabel}}</b> {{ o.label  }}</span>
            <input :name="name" :type="radio ? 'radio' : 'checkbox'" :value="o.value"  @change="$emit('change', value)" v-model="value">
            <span class="customCheckbox"></span>
        </label>
    </div>
</template>

<script>
export default {
    name: "CheckboxGroup",
    props: { options: Array, radio: Boolean, name: String, width: String, column: true, unclickableLabel: Boolean },
    data() {
        return {
            value: [],
        }
    },
    methods: {
        handleChange() {
            this.$emit('change', this.value);
        }
    },
    created() {
        if (this.radio) {
            this.value = this.options.find(o => o.checked).value;
        } else {
            this.value = this.options.filter(o => o.checked).map(a => a.value);
            this.handleChange();
        }
    }
}
</script>

<style scoped>

</style>