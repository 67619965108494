<template>
    <div v-if="buttons && survey" class="surveyContainer">
        <div class="inputRow">
            <div class="uppercaseTitle">Tipi vprašanj</div>
        </div>
        <div class="inputRow">
            <p>Izberite tip vprašanja spodaj in s klikom nanj vprašane dodajte v vaš vprašalnik.</p>
        </div>
        <div class="inputRow noHeight">
            <div class="qTypeButtonGroup">
                <q-type-btn v-for="(b,i) in buttonsFiltered" class="qtBtn" :key="i" :data="b" @click="addType" />
            </div>
        </div>

        <label class="singleCheckboxContainer">
            <span><b>Prikaži vsebino vseh vprašanj</b></span>
            <input name="closeAllQ" type="checkbox" v-model="opened" :checked="opened" @click="showAll">
            <span class="customCheckbox"></span>
        </label>

        <draggable v-model="survey.questions" v-bind="dragOptions" @start="drag=true" @end="drag=false" @change="handleMove" handle=".handle">
            <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                <div v-for="(element, i) in survey.questions" :key="i">
                    <draggable-item ref="draggableItems" :item="element" :opened="opened" @duplicate="duplicateQ" @delete="deleteQ" @openChange="handleOpenChange" />
                </div>
            </transition-group>
        </draggable>

        <confirm-dialogue ref="confirmDialogue" okBtn="Izbriši" cancelBtn="Prekliči" message="Ali ste prepričani, da želite izbrisati vprašanje?"></confirm-dialogue>

    </div>
</template>

<script>
import draggable from 'vuedraggable'

export default {
    name: "SurveyStep2",
    props: { survey: Object, buttons: Array },
    components: {
        draggable,
    },
    data() {
        return {
            drag: false,
            loading: false,
            opened: true,
        }
    },
    methods: {
        addType(value) {
            let index = this.survey.questions.length;
            let newQ = {
                order: index + 1,
                question_type_id: value.id,
                banner: null,
                index: index,
                qName: "Vprašanje " + index,
                fixed: false,
                mandatory: false,
                cond: false,
                limit: 2,
                explain: null,
                explain_question: null,
                type: value.type,
                typeDescription: value.name,
                possible_answers: []
            }
            if (this.hasReview()) {
                newQ.index--;
                newQ.order--;
                this.survey.questions[index-1].index++;
                this.survey.questions[index-1].order++;
                this.survey.questions.splice(index - 1, 0, newQ);
            } else {
                this.survey.questions.push(newQ);
            }
        },
        handleMove() {
            this.checkMove();
            this.survey.questions.forEach((q, i) => {
                q.order = i + 1;
            });
        },
        checkMove() {
            let reviewIndex = this.survey.questions.findIndex(a => a.type == "review");
            let lastIndex = this.survey.questions.length-1;
            if (reviewIndex > -1 && this.survey.questions[lastIndex].type != "review") {
                let review = this.survey.questions[reviewIndex];
                this.survey.questions.splice(reviewIndex, 1);
                this.survey.questions.splice(lastIndex, 0, review);
            }
        },
        async deleteQ(item) {
            const confirm = await this.$refs.confirmDialogue.open();
            if (confirm) {
                this.survey.questions = this.survey.questions.filter(q => q.index != item);
            }
        },
        loadQuestions() {
            if (this.buttons.length > 0) {
                let i = 0;
                this.survey.questions.forEach(q => {
                    let type = this.buttons.find(t => t.id == q.question_type_id);
                    q.index = i;
                    q.type = type.type;
                    q.typeDescription = type.name;
                    q.qName = q.name;
                    i++;
                })
            }
        },
        loadTypes() {
            let url = "/question-type/index";
            api.post(url).then(response => {
                this.$emit("saveButtons", response.data.data);
            }).catch(error => {
                this.errorCatching(error);
            })
        },
        duplicateQ(item) {
            let tmp = JSON.parse(JSON.stringify(item));
            delete tmp.id;
            tmp.index = this.survey.questions.length + 1;
            tmp.order = this.survey.questions.length + 1;
            tmp.possible_answers.forEach(a => {
                delete a.id;
                delete a.question_id;
            })
            if (this.hasReview()) {
                tmp.index--;
                tmp.order--;
                this.survey.questions[this.survey.questions.length-1].index++;
                this.survey.questions[this.survey.questions.length-1].order++;
                this.survey.questions.splice(this.survey.questions.length - 1, 0, tmp);
            } else {
                this.survey.questions.push(tmp);
            }
        },
        hasReview() {
            let flag = false;
            this.survey.questions.forEach((a, i) => {
                if (a.type == "review")
                    flag = true;
            })
            return flag;
        },
        showAll() {
            this.opened = !this.opened;
            this.$refs.draggableItems.forEach(i => {
                i.open = this.opened;
            })
        },
        handleOpenChange() {
            this.opened = this.allOpened;
        }
    },
    created() {
        if (!this.buttons)
            this.loadTypes();
    },
    computed: {
        dragOptions() {
            return {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost",
                scrollSensitivity: 200,
                forceFallback: true
            }
        },
        buttonsFiltered() {
            let btns =  this.buttons.filter(b => b.type != "review");
            let review = this.buttons.find(b => b.type == "review");
            if (!this.hasReview()) btns.push(review);
            if (this.survey.special_type === "MBA") {
                // v tipu "mba" samo lestvica 1-5 in dolg besedilni odgovor
                btns = btns.filter(b => b.id == 4 || b.id == 6);
            }
            return btns;
        },
        allOpened() {
            let flag = true;
            this.$refs.draggableItems.forEach(i => {
                if(i.open == false) flag = false;
            })
            return flag;
        }
    },
    watch: {
        buttons: {
            deep: true,
            handler() {
                this.loadQuestions();
            }
        }
    }
}
</script>

<style scoped>

.qTypeButtonGroup {
    margin: 25px 0;
    display: flex;
}

.qtBtn {
    margin-right: 26px;
}

.qtBtn:last-child {
    margin-right: 0;
}

.mainColorBtn {
    padding: 15px;
    margin-left: auto;
}

</style>