<template>
    <div class="vprasanje-container da-ne-odgovor">
        <span class="st-vprasanja">{{ question.order }}. {{ __("analysis.question", locale) }}</span>
        <span class="vprasanje">{{ question.name }}</span>
        <table class="st no-page-break" v-if="dataAnalysis">
            <tr>
                <th>{{ __("analysis.answer", locale) }}</th>
                <th>{{ __("analysis.frequency", locale) }}</th>
                <th>{{ __("analysis.percentage", locale) }}</th>
            </tr>
            <tr v-for="(v,k) in dataAnalysis.data" :key="k">
                <td>{{ k }}</td>
                <td>{{ v }}</td>
                <td>{{ roundMix(((v / frequencyTotal) * 100)) }}%</td>
            </tr>
            <tr class="final">
                <td>{{ __("analysis.sumarium", locale) }}</td>
                <td>{{ frequencyTotal }}</td>
                <td>100%</td>
            </tr>

            <span v-if="dataAnalysis.emails.length > 0" class="vprasanje komentar-naslov"> Emaili</span>
            <div v-if="dataAnalysis.emails.length > 0" style="width: 300%">
                <span v-for="(c,i) in dataAnalysis.emails" :key="i+'feedback'">
                    <span class="komentar">{{ c }}</span>
                </span>
            </div>

            <span v-if="dataAnalysis.explanations.length > 0"
                  class="vprasanje komentar-naslov"> {{ __("analysis.comment", locale) }}</span>
            <div v-if="dataAnalysis.explanations.length > 0" style="width: 300%">
                <span v-for="(c,i) in dataAnalysis.explanations" :key="i+'feedback'">
                    <span class="komentar">{{ c }}</span>
                </span>
            </div>

        </table>
        <div class="grafi">
            <div class="graf" style="width:30%">
                <canvas :id="'graph-check-anwser'+this.question.id" style="width:100%;max-width:400px"></canvas>
            </div>
        </div>
    </div>
</template>

<script>

import {Chart, registerables} from 'chart.js';

export default {
    name: "AnlYesNoAnswer",
    props: {locale: String, question: Object},
    data() {
        return {

            dataAnalysis: null,
            frequencyTotal: 0,
        }
    },
    methods: {
        load() {
            let url = "/analysis/query-answer/" + this.question.id;
            api.post(url).then(response => {
                this.dataAnalysis = response.data.data;
                this.drawGraph(document.getElementById('graph-check-anwser' + this.question.id), this.dataAnalysis.data);
            }).finally(() => {
                this.$emit("loaded");
            });
        },
        drawGraph(ctx, data, format = "pie") {
            let tmp = JSON.parse(JSON.stringify(data));
            let labels = Object.keys(tmp);
            let graphData = {
                type: format,
                data: {
                    labels: labels,
                    datasets: [{
                        backgroundColor: ["#ffa4af", "#c5093b"],
                        data: Object.values(tmp),
                    }]
                },
                options: {
                    plugins: {
                        datalabels: {
                            display: true,
                            align: 'center',
                            anchor: 'center',
                            font: {
                                size: 15
                            },
                            color: "#ffffff",
                            formatter: function (value, index, values) {
                                if (value === 0)
                                    return "";
                                if (value !== 0) {
                                    return value;
                                }
                            }
                        }

                    },
                     animation: {
        duration: 0
    },

                }
            };
            console.log(graphData.data.datasets.backgroundColor)
            new Chart(ctx, graphData);
        }
    },
    watch: {
        "dataAnalysis.data": {
            deep: true,
            handler(value) {
                if (value) {
                    this.frequencyTotal = Object.values(JSON.parse(JSON.stringify(value))).reduce((partial_sum, a) => partial_sum + a, 0);
                }
            },
        }
    },
    created() {
        this.load();
    },

}

</script>


<style scoped>
.komentar-naslov {
    margin-top: 30px;
    font-weight: bold;
    margin-bottom: 6px;
}

.komentar {
    font-size: 14px;
    display: inline-block;
    margin-right: 5px;
    padding: 5px 10px;
    margin-bottom: 3px;
    border-radius: 4px;
    width: fit-content;
    background-color: #e6e6e6;
}

.vprasanje-container {
    page-break-inside: avoid;
}

.final td {
    border-top: 1px solid black;
    font-weight: bold;
    padding-top: 10px;
    text-transform: uppercase;
}

.st-vprasanja {
    margin-top: 40px;
    display: block;
    font-size: 14px;
    font-weight: 300;
}

.vprasanje {
    font-weight: 600;
    font-size: 14px;
    display: block;
}

.st tr td {
    width: 33.33%;
}

.st th {
    font-size: 14px !important;
}

tr td, tr th {
    text-align: center;
}


th {
    padding-bottom: 10px;
    font-weight: bold;
    text-transform: uppercase;
    border-bottom: 1px solid black;
}

table td {
    padding: 3px 0;
}

table {
    width: 100%;
    margin-top: 30px;
    margin-left: -2px;
    font-size: 14px;
}

table tr td:last-child, table tr th:last-child, table tr td:last-child, table tr th:last-child {
    text-align: right !important;
}

table tr td:first-child, table tr th:first-child, table tr td:first-child, table tr th:first-child {
    text-align: left !important;
}

.grafi {
    margin-top: 20px;
    justify-content: center;
    display: flex;
    flex-flow: inherit;
}

.graf {
    background-color: #fff;
    width: 50%;
}

.graf canvas {
    padding: 10px;;
}

</style>