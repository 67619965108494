<template>
    <div>
        <div class="settingsRow settingsRowColumn">
            <div class="uppercaseTitle">Uporabniki</div>
            <div class="usersContainer">
                <settings-user v-for="(u ,i) in users" :key="i" :user="u" @reload="load" @loading="handleLoading" />
                <settings-user :addNew="true" @reload="load" />
            </div>
        </div>
        <div v-if="loading" class="loaderContainerAbsolute">
            <div class="loader"></div>
        </div>
        <div v-if="loading" class="loaderOverlay"></div>
    </div>
</template>

<script>
export default {
    name: "Settings",
    data() {
        return {
            sidebar: [
                {label: "Nastavitve", active: true, disabled: true},
                {label: "Nazaj", name:"AdminEntry", active: false },
            ],
            users: [],
            loading: false,
        }
    },
    methods: {
        load() {
            this.loading = true;
            let url = "/user/index"
            api.post(url).then(response => {
                this.users = response.data.data;
                this.loading = false;
            }).catch(error => {
                this.loading = false;
                let errors = Object.keys(this.errorCatching(error).errors);
                this.$root.$children[0].$refs.warningRef.show("Prišlo je do napake. Koda napake: " + errors);
            });
        },
        handleLoading(value) {
            this.loading = value;
        }
    },
    created() {
        this.$emit("sidebar", this.sidebar);
        this.load();
    },
}
</script>

<style scoped>

.settingsRow {
    width: 100%;
    margin: 30px 0;
    margin-bottom: 60px;
    display: flex;
    align-items: center;
}

.settingsRow:last-child {
    margin-bottom: 30px;
}

.settingsRow.settingsRowColumn {
    align-items: unset;
    flex-direction: column;
}

.uppercaseTitle {
    padding-right: 5px;
}

.usersContainer {
    width: 100%;
}

.loaderOverlay {
    position: fixed;
    top: 0;
    bottom: 0;
    height: 100%;
}

.loader {
    position: fixed;
}


</style>