<template>
    <div class="testimonialContainer">
        <div class="testimonialImage">
            <img v-if="img" :src="prependUrl(img)" alt="">
            <div v-else class="imagePlaceholder">
                <span>DODAJ SLIKO</span>
            </div>
            <label class="inputLabel cursor-pointer no-wrap" :for="name">
                <input type="file" accept="image/*" @change="uploadImage($event)" :id="name">
            </label>
            <button v-if="img" @click.stop="remove" class="iconBtn"><img src="../../assets/img/trash_icon.svg" alt="Delete"></button>
        </div>
    </div>


</template>

<script>
export default {
    name: "TestimonialUpload",
    props: { link: String, name: String, oldSrc: String},
    data() {
        return {
            img: this.oldSrc,
            filename: null,
        }
    },
    methods: {
        uploadImage(event) {
            let image = event.target.files[0];
            this.filename = image.name;

            let reader = new FileReader();
            reader.readAsDataURL(image);

            this.uploadImageDb(image).then(url => {
                this.$emit('upload', url, this.name);
                this.img = url;
            });
        },
        remove() {
            this.img = false;
            this.filename = null;
            this.$emit('upload', null, this.name);
        }
    }
}
</script>

<style scoped>

.iconBtn {
    position: absolute;
    width: 40px;
    height: 40px;
    top: 5px;
    right: 5px;
    display: none;
    z-index: 6;
}

.iconBtn:hover {
    transform: scale(1.2);
}

.testimonialImage:hover .iconBtn {
    display: block;
}

.testimonialContainer{
    display: inline-block;
    height: 100%;
    margin-right: 30px;
}

.testimonialImage {
    position: relative;
    width: 300px;
    height: 200px;
}

.inputLabel {
    height: 100%;
    width: 100%;
    z-index: 5;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

img, .imagePlaceholder {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

/* img, .imagePlaceholder {
    margin: 30px 30px 15px 0;
    width: 300px;
    height: 200px;
} */

.imagePlaceholder{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #e4e4e4;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    align-content: space-between;
    align-items: center;
    z-index: 1;
}

.imagePlaceholder span{
    color: #fff;
    font-size: 24px;
}

.testimonialTitle{
    margin: 15px 30px 15px 0;
}

.testimonialTitle input{
    background-color: #f6f6f6;
}

.testimonialDesc{
    margin: 15px 30px 15px 0;
}

.testimonialDesc textarea{
    width: 100%;
    background-color: #f6f6f6;
}


.textAreaOpis{
    font-size: 15px;
    padding: 10px 25px;
}

</style>