<template>
    <div v-if="answer.value !== ''" class="odgovor" :class="{editing: editing}">
        <textarea v-if="editing" v-model="answer.value" ref="editTextarea" @blur="updateAnswer(false)" @keydown.enter="updateAnswer(false)" class="editAnswerInput" rows="4"></textarea>
        <span v-else >{{ answer.value }}</span>
        <div v-if="question.special_type == 'MBA' && !editing" class="odgovorBtns">
            <button @click="edit" class="iconBtn"><img src="../../../assets/img/edit_icon.svg" title="Uredi" alt="Edit"></button>
            <button @click="deleteAnswer" class="iconBtn"><img src="../../../assets/img/trash_icon.svg" title="Izbriši" alt="Delete"></button>
        </div>
    </div>
</template>

<script>

export default {
    name: "AnlTextAnswerRow",
    props: {answer:Object, question:Object},
    data() {
        return {
            editing: false
        }
    },
    methods: {
        async deleteAnswer() {
            const confirm = await this.$parent.$refs.confirmDialogue.open();
            if (confirm) {
                this.answer.value = "";
                this.updateAnswer(this.answer, true);
            }
        },
        updateAnswer(reload) {
            this.editing = false;
            let url = "/answer/update/" + this.answer.id;
            api.post(url, this.answer).then(response => {
                if (reload)
                    this.$emit("reload")
            });
        },
        edit() {
            this.editing = true;
            this.$nextTick( () => {
                this.$refs.editTextarea.focus();
            })
        },
    },
}

</script>

<style scoped>
.odgovor{
    position: relative;
    font-size: 14px;
    display: block;
    margin-top: 10px;
    padding: 10px 15px;
    padding-right: 30px;
    border-radius: 4px;
    background-color: #e6e6e6;
}

.odgovor.editing {
    border: solid 1px var(--main-color)
}

.odgovorBtns {
    position: absolute;
    right: -84px;
    top: 3px;
}

.iconBtn img {
    width: 30px;
}

.editAnswerInput {
    width: 100%;
    border-radius: 4px !important;
    background: none;
    resize: none;
    border: none;
    font-size: inherit;
    color: inherit;
    font-family: inherit;
}

@media print {
    .odgovorBtns {
        display: none;
    }

    .iconBtn {
        display: none;
    }

    .iconBtn img {
        display: none;
    }
}

</style>