<template>
    <div v-if="!loading">
        <transition name="router">
            <step1 v-if="currentStep == 1" :savedSurvey="survey" :folders="folders" :socialOptions="socialOptions" @survey="handleSurvey" />
            <step2 v-else-if="currentStep == 2" :survey="survey" :buttons="buttons" @saveButtons="handleButtons" @survey="handleSurvey" />
            <step3 v-else-if="currentStep == 3" :survey="survey" :qrCode="qrCode" :link="link" @saveQR="handleQR" @publish="publishSurvey" @finish="finishSurvey" />
        </transition>
        <div class="fixedButtons">
            <button class="roundedStyle mainColorBtn saveButton" v-if="survey.status != 'draft'" @click="$router.push({name: 'Analysis', params: {id: survey.id}})">ANALIZA</button>
            <button class="roundedStyle mainColorBtn saveButton" v-if="survey.status != 'finished'" @click="saveSurvey">SHRANI</button>
            <div v-if="saving" class="loaderContainerAbsolute">
                <div class="loader"></div>
            </div>
            <div v-if="saving" class="loaderOverlay"></div>
            <div v-if="alert" class="absoluteTestAlert" :class="alert.color">{{ alert.text }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: "EditSurvey",
    props: { id: String|Number },
    data() {
        return {
            survey: {},
            sidebar: [
                {label: "Urejanje ankete", disabled: true, active: true},
                {label: "Podrobnosti", name: "tabs", tab: 1, active: true, hide: false},
                {label: "Vprašanja", name: "tabs", tab: 2, active: false, hide: false},
                {label: "Nastavitve", name: "tabs", tab: 3, active: false, hide: false},
                {label: "Nazaj", name: "back", active: false},
            ],
            loading: false,
            currentStep: 1,
            socialOptions: [],
            buttons: null,
            folders: [],
            qrCode: null,
            link: null,
            alert: false,
            saving: false,
        }
    },
    methods: {
        loadSurvey() {
            this.loading = true;
            let url = "/survey/show/" + this.id;
            api.post(url).then(response => {
                this.survey = response.data.data;
                this.link = this.survey.status == "published" ? this.prependUrlFront("anketa/" + this.survey.token) : null;

                this.setSidebar();
                    
                this.loading = false;
            })
        },
        setSidebar() {
            if (this.survey.status === "published") {
                this.sidebar[2].hide = true;
            } else if (this.survey.status === "finished") {
                this.changeTab(3);
                this.sidebar[1].hide = true;
                this.sidebar[2].hide = true;
            }

            this.$emit("sidebar", this.sidebar);
        },
        changeTab(tab) {
            this.sidebar.find(a => a.tab == this.currentStep).active = false;
            this.sidebar.find(a => a.tab == tab).active = true;
            this.currentStep = tab;
            this.$emit("sidebar", this.sidebar);
        },
        handleSurvey(value) {
            this.survey = value;
        },
        async saveSurvey() {

            if (this.survey.published_to && this.survey.published_from > this.survey.published_to)
                this.survey.published_to = this.getTomorrow(this.survey.published_from);

            this.saving = true;
            let url = "/survey/update/" + this.survey.id;

            let data = JSON.parse(JSON.stringify(this.survey));

            if (this.survey.status != "draft") {
                delete data.mailed;
                delete data.questions;
            }

            try {
                const saveResponse = await api.post(url, data);
                console.log("save", saveResponse)
                this.saving = false;
                this.saveSuccess = true;
                this.$root.$children[0].$refs.warningRef.show("Anketa je bila uspešno shranjena.");
                return true;
            } catch(error) {
                this.saving = false;
                let errors = this.errorCatching(error);
                if (errors) {
                    let firstError = errors.errors[Object.keys(errors.errors)[0]][0];
                    this.$root.$children[0].$refs.warningRef.show(firstError);
                } else {
                    this.$root.$children[0].$refs.warningRef.show("Prišlo je do napake. Prosimo izpolnite polja pri vprašanjih.");
                }
                return false
                // TODO-maks: prikaz validacije
            }

            
        },
        loadLinks() {
            let url = "/link/index";
            api.post(url).then(response => {
                this.socialOptions = response.data.data;
            }).catch(error => {
                this.errorCatching(error);
            });
        },
        loadFolders() {
            let url = "/folder/find";
            api.post(url).then(response => {
                console.log(response)
                this.folders = Object.values(response.data.data);
            }).catch(error => {
                let errors = Object.keys(this.errorCatching(error).errors);
                this.$root.$children[0].$refs.warningRef.show("Prišlo je do napake. Koda napake: " + errors);
            })
        },
        handleButtons(value) {
            this.buttons = value;
        },
        handleQR(value) {
            this.qrCode = value;
        },
        async publishSurvey() {
            if (this.survey.questions.length) {
                this.survey.published_from = null;
                const publishResponse = await this.saveSurvey();
                if (publishResponse) {
                    let url = "/survey/publish/"+this.survey.id;
                    api.post(url).then(response => {
                        this.loadSurvey();
                    }).catch(error => {
                        this.errorCatching(error);
                    })
                }
            } else {
                this.$root.$children[0].$refs.warningRef.show("Objava ni uspela. Anketa ne vsebuje vprašanj.");
            }
        },
        finishSurvey() {
            let url = "/survey/finish/"+this.survey.id;
            api.post(url).then(response => {
                this.loadSurvey();
            }).catch(error => {
                this.errorCatching(error);
            })
        },
    },
    created() {
        this.loadSurvey();
        this.loadLinks();
        this.loadFolders();
    },
}
</script>

<style scoped>

.loader {
    width: 30px;
    height: 30px;
}

.fixedButtons {
    position: fixed;
    right: 0;
    bottom: 10vh;
    width: 155px;
}

.saveButton {
    width: 100%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-top: 15px;
}

.saveButton:hover {
    transform: scale(1.05);
}

.absoluteTestAlert {
    position: absolute;
    font-weight: bold;
    font-size: 14px;
}

.greenAlert {
    color: green;
}

.redAlert {
    color: red;
}

</style>