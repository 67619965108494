<template>
    <button class="qTypeButton" @click="handleClick">
        <img :src="prependUrl(data.icon)" class="nonHoverImg">
        <img src="../../assets/img/add_icon.svg" class="hoverImg" alt="">
        <span>{{ data.name }}</span>
    </button>
</template>

<script>
export default {
    name: "QuestionTypeButton",
    props: { data: Object },
    methods: {
        handleClick() {
            this.$emit("click", this.data);
        }
    },
}
</script>

<style scoped>

.qTypeButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 13px;
    font-weight: normal;
    text-transform: none;
    background: none;
    color: var(--dark-grey);
    width: 80px;
}

.qTypeButton span {
    margin-top: 20px;
}

.qTypeButton img {
    height: 70px;
    width: 70px;
}

.hoverImg {
    display: none;
}

.qTypeButton:hover .nonHoverImg {
    display: none;
}

.qTypeButton:hover .hoverImg {
    display: unset;
}

</style>