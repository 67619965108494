<template>
    <div v-if="!data.hide" class="inputRow" :class="{noHeight: data.type == 'textarea' || data.type == 'mailGroup'}">
        <div v-if="!data.title" class="inputName inputCol">{{ data.name }}</div>
        <div v-else class="inputName inputCol strongerText">{{ data.title }}</div>

        <div v-if="data.type == 'text' || data.type == 'password'" class="inputCol">
            <input :type="data.type" class="textAreaStyle roundedStyle" :placeholder="data.placeholder" v-model="value" :required="data.required" @input="$emit('change', value)" :disabled="data.disabled">
        </div>

        <div v-else-if="data.type == 'textarea'" class="inputCol">
            <textarea :type="data.type" class="textAreaStyle roundedStyle" :placeholder="data.placeholder" v-model="value" :rows="data.rows || 5" @input="$emit('change', value)" :disabled="data.disabled"></textarea>
        </div>

        <div v-else-if="data.type == 'file'" class="inputCol fileInputCol">
            <label class="roundedStyle textAreaStyle cursor-pointer no-wrap" :for="data.id">
                <input type="file" accept="image/*" @change="uploadImage($event)" :id="data.id">
                {{ data.placeholder }}
            </label>
            <div class="fileName" v-if="filename"><a class="linkStyleBtn" :href="prependUrl(filename)" target="_blank">Poglej sliko</a></div>
        </div>

        <div v-else-if="data.type == 'checkbox'" class="inputCol">
            <checkbox-group :options="data.options" :radio="true" width="140px" :name="nameNoSpaces" @change="handleChange" />
        </div>

        <div v-else-if="data.type == 'select'" class="inputCol">
            <div class="textAreaStyle roundedStyle roundedWithSelect">
                <select v-model="value" class="selectInRounded" @change="$emit('change', value)">
                    <option :value="null">{{data.placeholder}}</option>
                    <option v-for="(o, i) in data.options" :key="data.key + i" :value="o.id">{{o.name}}</option>
                </select>
            </div>
        </div>

        <div v-else-if="data.type == 'mailGroup'" class="inputCol">
            <div class="roundedStyle textAreaStyle flexWrap mailGroupBox">
                <span v-if="addresses.length > 0" class="flexWrap singleAddressBox">
                    <span v-for="(a, i) in addresses" :key="i" class="ccAddress" :class="validateAddress(a.email)" @click="deleteAddress(i)">
                        {{a.email}}
                        <div class="ccAddressX">&times;</div>
                    </span>
                </span>
                <input type="email" class="textAreaStyle inputNoStyle singleAddressBox" v-model="newAddress" @blur="handleAddressInput" @keyup.enter="handleAddressInput">
            </div>
        </div>

        <div v-else-if="data.type == 'datetime'" class="inputCol">
            <VueCtkDateTimePicker
                v-model="value"
                format="Y-MM-DDTHH:mm"
                formatted="DD.MM.Y HH:mm"
                color="var(--main-color)"
                :label="data.placeholder"
                :no-label="true"
                :no-button-now="true"
                locale="sl"
                :min-date="data.min"
                :right="true"
                :id="data.key"
                @input="handleChange"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: "CreateSurveyInput",
    props: { data: Object, oldValue: String|Number },
    data() {
        return {
            filename: this.oldValue,
            addresses: this.oldValue || [],
            newAddress: null,
            value: this.oldValue || null
        }
    },
    methods: {
        uploadImage(event) {
            
            this.filename = null;
            let image = event.target.files[0];

            let reader = new FileReader();
            reader.readAsDataURL(image);

            this.uploadImageDb(image).then(url => {
                this.handleChange(url);
                this.filename = url;
            });
        },
        handleChange(value) {
            this.$emit('change', value);
        },
        handleAddressInput() {
            if(this.newAddress) {
                let splitAddress =  this.newAddress.split(/[\s,]+/);
                let objectAddress = [];
                splitAddress.forEach(a => {
                    objectAddress.push({email: a});
                });
                this.addresses = this.addresses.concat(objectAddress)
                // this.addresses.push({email: this.newAddress});
                this.newAddress = null;
                this.handleChange(this.addresses);
            }
        },
        validateAddress(address) {
            const re = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;
            return re.test(String(address).toLowerCase()) ? "" : "invalidAddress";
        },
        deleteAddress(index) {
            this.addresses.splice(index, 1);
        }
    },
    computed: {
        nameNoSpaces() {
            return this.data.name.replace(/\s/g, "");
        }
    },
}
</script>

<style scoped>

.singleAddressBox {
    height: fit-content;
}


.fileInputCol {
    display: flex;
    align-items: center;
}

.fileInputCol label {
    width: fit-content!important;
}

.fileInputCol .fileName {
    padding-left: 30px;
    white-space: nowrap;
}

.flexWrap {
    display: flex;
    flex-wrap: wrap;
}

.inputNoStyle {
    border: none !important;
    box-shadow: none !important;
    padding: 0 !important;
    width: 100% !important;
    font-size: 15px;
}

.invalidAddress {
    color: red;
}

.ccAddress {
    position: relative;
    background: #fff;
    padding: 3px;
    font-size: 12px;
    margin-right: 6px;
    border-radius: 6px;
    cursor: pointer;
    border: 1px solid var(--light-grey);
    margin-bottom: 5px;
}

.ccAddress:hover {
    transform: scale(1.1);
}

.ccAddressX {
    display: none;
    position: absolute;
    top: -6px;
    right: -2px;
    color: var(--dark-grey);
    font-size: 12px;
    font-weight: bold;
}

.ccAddress:hover .ccAddressX {
    display: block;
}

.mailGroupBox {
    background: #fff;
}

</style>