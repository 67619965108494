import router from "@/router";
import _ from "lodash";
import {mapGetters} from './store/index.js'


function valueOrDefault(val, def) {
    if (val)
        return val;
    return def;
}

export default {
    methods: {
        marginClass: val => {
            switch (val) {
                case "normal":
                    return "normalMargin";
                case "auto":
                    return "pageContent";
                default:
                    return "";
            }
        },
        sidebarOpen: val => {
            if (val.name == "back")
                router.go(-1);
                // else if (val.name == "url")
            //     window.open(val.url, '_blank');
            else if (val.name == "tabs")
                return val.tab;
            else
                router.push({name: val.name, params: val.params});
        },
        prependUrl: val => {
            return process.env.VUE_APP_API_URL + "/" + val;
        },
        prependUrlFront: val => {
            return process.env.VUE_APP_URL + "/" + val;
        },
        uploadImageDb: async function (image) {
            let url = "/data/upload-image";
            let formData = new FormData();
            formData.append("image", image);
            let headers = {
                headers: {
                    "Content-Type": 'multipart/form-data',
                }
            };
            formData.append("headers", headers);
            try {
                const response = await api.post(url, formData, headers);
                return response.data.data;
            } catch (error) {
                console.log(error.message);
            }
        },
        errorCatching: e => {
            if (e.response.status == 422)
                return e.response.data;
            else {
                console.log(e.response);
                // prikaz e.response.data.msg za uporabnika
                // TODO na koncu: shranjevanje v bazo za dev
            }
        },
        __(key, locale = "sl") {
            let translations = JSON.parse(localStorage.translations);
            let found = null;
            let group = null;
            try {
                group = key.split('.')[0];
                key = key.split('.')[1];
                found = translations[locale].php[group]
                return found[key];
            } catch (e) {
                return key;
            }
        },
        * randomHexColors(i) {
            for (let j = 0; j < i; j++) {
                yield "#" + Math.floor(Math.random() * 16777215).toString(16);
            }
        },
        * colorsPalette(i) {
            let colors = [
                "#c5093b",
                "#54b9c4",
                "#448087",
                "#324b72",
                "#2f4858",
                "#574141",
                "#ff8390",
                "#4f6c00",
                "#bfa5a5",
                "#ff6172",
                "#86a000",
                "#ffa4af",
                "#ea3d56",
            ];
            let index = 0;
            for (let j = 0; j < i; j++) {
                index++;
                yield colors[index - 1];
            }
        },
        colors1to5() {
            return [
                "#ffa4af",
                "#ff8390",
                "#ff6172",
                "#ea3d56",
                "#c5093b"
            ];
        },

        numbers(config) {
            var cfg = config || {};

            var min = valueOrDefault(cfg.min, 0);
            var max = valueOrDefault(cfg.max, 100);
            var from = valueOrDefault(cfg.from, []);
            var count = valueOrDefault(cfg.count, 8);
            var decimals = valueOrDefault(cfg.decimals, 8);
            var continuity = valueOrDefault(cfg.continuity, 1);
            var dfactor = Math.pow(10, decimals) || 0;
            var data = [];
            var i, value;

            for (i = 0; i < count; ++i) {
                value = (from[i] || 0) + this.rand(min, max);
                if (this.rand() <= continuity) {
                    data.push(Math.round(dfactor * value) / dfactor);
                } else {
                    data.push(null);
                }
            }

            return data;
        },
        roundMix(num) {
            return Math.round(num * 100) / 100;
        },
        average(array) {
            let sum = 0;
            let answers = 0;
            for (let i = 1; i < 6; i++) {
                sum += array[i] * i;
                answers += array[i];
            }
            return sum / answers;
        },
        getTomorrow(date) {
            let tomorrow;
            if (date)
                tomorrow = new Date(date);
            else
                tomorrow = new Date();
            tomorrow.setDate(tomorrow.getDate() + 1);
            return tomorrow.getFullYear() + "-" + ((tomorrow.getMonth() + 1) > 9 ? '' : '0') + (tomorrow.getMonth() + 1) + "-" + (tomorrow.getDate() > 9 ? '' : '0') + tomorrow.getDate() + "T00:00";
        },
        setBool(value) {
            if (typeof value == "string")
                return parseInt(value);
            else
                return value;
        },
        rand(min, max) {
            var _seed = Date.now();
            min = valueOrDefault(min, 0);
            max = valueOrDefault(max, 0);
            _seed = (_seed * 9301 + 49297) % 233280;
            return min + (_seed / 233280) * (max - min);
        },
        arraySum(ar) {
            let sum = 0;
            for (let i = ar.length; i--;)
                sum += parseInt(ar[i], 10);
            return sum;
        }
    }
}