<template>
    <div class="dragSurveyList">
        <div v-if="folder" class="draggableItemHeader roundedStyle" :class="{closed: !open}" @click="open = !open">
            <input @click.stop class="folderNameInput" type="text" v-model="folder.name" @blur="folderChange">
            <div class="folderActions flexElement">
                <span class="itemLength">Št. elementov: {{items.length}}</span>
                <button @click.stop="deleteFolder"><img src="../../assets/img/trash_icon_grey.svg" alt="Delete" class="trashBtnImg"></button>
                <button @click.stop class="handle"><img src="../../assets/img/drag_icon.svg" alt="Drag" class="dragBtnImg"></button>
            </div>
        </div>
        <transition name="fade">
            <draggable class="dragArea" chosen-class="chosenone" :class="{extend: (folder && isDragging)}" :list="items" v-bind="dragOptions" @start="drag=true" @end="drag=false" @change="handleMove" handle=".handle">
                <div v-for="item in openedItems" :key="itemKey(item)" :class="{levelDown: levelDown}">
                    <drag-survey-list v-if="item.items" ref="dragSurveyList" class="insideFolder" :folder="item" :items="item.items" :levelDown="true" @moved="handleMove" @dragged="handleDragged" @reload="$emit('reload')"/>
                    <drag-survey-row v-else :survey="item"/>
                </div>
            </draggable>
        </transition>

        <confirm-dialogue ref="confirmDialogue" :okBtn="confirmData.okBtn" :cancelBtn="confirmData.cancelBtn" :message="confirmData.message"></confirm-dialogue>
    </div>
</template>

<script>
import draggable from 'vuedraggable'

export default {
    name: "DraggableSurveyList",
    props: {folder: Object, items: Array|Object, levelDown: Boolean},
    components: {
        draggable,
    },
    data() {
        return {
            drag: false,
            open: Boolean(!this.folder),
            isDragging: false,
            confirmData: {
                okBtn: "Potrdi",
                cancelBtn: "Prekliči",
                message: "POZOR! To dejanje bo dokončno izbrisalo mapov vse njene podmape in ankete.",
            }
        }
    },
    methods: {
        handleMove(value) {
            this.$emit("moved", value);
        },
        folderChange() {
            let url = "/folder/update/" + this.folder.id;
            api.post(url, this.folder).then(response => {
                console.log(response)
            }).catch(error => {
                console.log(error);
            })
        },
        async deleteFolder() {
            const confirm = await this.$refs.confirmDialogue.open();
            if (confirm) {
                let url = "/folder/destroy/" + this.folder.id;

                api.post(url).then(response => {
                    this.$emit("reload");
                }).catch(error => {
                    console.log(error);
                });
            }
        },
        itemKey(item) {
            if (item.items)
                return "folder"+item.id;
            return "survey"+item.id;
        },
        openAll() {
            this.open = true;
            this.$nextTick(() => {
                if (this.$refs.dragSurveyList)
                    if (this.$refs.dragSurveyList.length)
                        this.$refs.dragSurveyList.forEach(d => {
                            d.openAll()
                        });
                    else
                        this.$refs.dragSurveyList.openAll();
            })
        },
        handleDragged(value) {
            this.$emit("dragged", value)
        },
        dragging(value) {
            this.isDragging = value;
            this.$nextTick(() => {
                if (this.$refs.dragSurveyList) {
                    if (this.$refs.dragSurveyList.length)
                        this.$refs.dragSurveyList.forEach(d => {
                            d.dragging(value)
                        });
                    else
                        this.$refs.dragSurveyList.dragging(value);
                }
            })
        }
    },
    computed: {
        dragOptions() {
            return {
                animation: 200,
                group: {name: "description", pull: true, put: true},
                ghostClass: "ghost",
                scrollSensitivity: 200,
                forceFallback: true
            }
        },
        openedItems() {
            if(this.open)
                return this.items;
            return [];
        }
    },
    watch: {
        drag: {
            deep: true,
            handler(value) {
                this.$emit("dragged", value)
            }
        }
    }
}
</script>

<style scoped>

.dragArea.extend {
    position: relative;
    min-height: 75px;
    background-color: var(--background-grey);
    box-shadow: 0px 0px 15px 2px var(--background-grey);
}

.chosenone .dragArea.extend {
    min-height: unset;
    background-color: unset;
    box-shadow: unset;
}

.itemLength {
    margin-right: 1.6rem;
    font-size: 0.9rem;;
}

.draggableItemHeader {
    position: relative;
    display: flex;
    align-items: center;
    background-color: var(--light-grey);
    border: solid 1px var(--light-grey);
    color: var(--dark-grey);
    text-align: left;
    padding: 12px 30px;
    font-size: 18px;
    margin-left: -15px;
    margin-right: -15px;
    text-transform: none;
    margin-top: 30px;
    padding-left: 55px;
    font-weight: 600;
    cursor: pointer;
    user-select: none;
}

.draggableItemHeader::before {
    content: "";
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translate(0, -50%);
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 8px solid var(--dark-grey);
}

.draggableItemHeader.closed::before {
    border-top: 8px solid var(--dark-grey);
    border-bottom: none;
}

.dragSurveyList {
    padding-bottom: 15px;
}

.levelDown {
    padding-left: 30px;
}

.folderActions {
    margin-left: auto;
}

.folderActions button {
    display: flex;
}

.dragBtnImg {
    height: 28px;
}

.trashBtnImg {
    height: 23px;
    margin-right: 10px;
}

.folderNameInput {
    border: none;
    background: none;
    color: var(--dark-grey);
    font-weight: 600;
    padding: 0 !important;
    font-size: 18px;
}

</style>