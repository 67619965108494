<template>
    <div class="adminAnswerContainer">
        <div class="flexElement">
            <input type="text" class="roundedStyle textAreaStyle" placeholder="Vpišite besedilo vprašanja" v-model="item.name" @blur="changeName">
        </div>
        <div class="adminAnswersInput">
            <div class="adminAnswersInputSingle">
                <div class="roundedStyle textAreaStyle textCenter">DA</div>
            </div>
            <div class="adminAnswersInputSingle">
                <div class="roundedStyle textAreaStyle textCenter">NE</div>
            </div>
        </div>
        
        <div class="admConditionContainer">
            <checkbox-group :options="conditionalCheckbox"  width="100%" :name="item.type + item.index" @change="handleConditional" />
            <div class="admConditionalInput">
                <div class="admLabel">Besedilo zahtevanega vnosa</div>
                <input type="text" class="textAreaStyle roundedStyle" placeholder="npr. Vaš e-poštni naslov" v-model="item.placeholder">
            </div>
            <div class="admConditionalInput">
                <div class="admLabel">Opomba</div>
                <input type="text" class="textAreaStyle roundedStyle" placeholder="npr. strinjanje s pogoji poslovanja" v-model="item.note">
            </div>

            <checkbox-group :options="conditionalNoCheckbox" class="normalMargin" width="100%" :name="'no' + item.type + item.index" @change="handleConditionalNo" />
            <div class="admConditionalInput">
                <div class="admLabel">Besedilo zahtevanega vnosa</div>
                <input type="text" class="textAreaStyle roundedStyle" placeholder="npr. Zakaj ne?" v-model="item.explain_question">
            </div>
        </div>
        <div class="shortRowContainer">
            <create-survey-input :data="inputFileData" @change="handleImage" />
        </div>

        <checkbox-group :options="checkbox" width="100%" :name="item.type+item.index" @change="handleChange" />
    </div>
</template>

<script>
export default {
    name: "AdmYesNoAnswer",
    props: {item: Object},
    data() {
        return {
            inputFileData: {type: "file", name: "Izberi sliko naslovnice", placeholder: "Izberite datoteko"},
            checkbox: [{boldLabel: "Obvezen odgovor:", label: "anketirancu bo onemogočeno nadaljevanje, dokler ne odgovori na vprašanje.", checked: this.setBool(this.item.mandatory)}],
            conditionalCheckbox: [{boldLabel: "POGOJ DA:", label: "Ob kliku na odgovor DA  se anketirancu poda dodatno besedilno polje.", checked: this.setBool(this.item.cond)}],
            conditionalNoCheckbox: [{boldLabel: "POGOJ NE:", label: "Ob kliku na odgovor NE  se anketirancu poda dodatno besedilno polje.", checked: this.setBool(this.item.explain)}],
        }
    },
    methods: {
        handleChange(value) {
            this.item.mandatory = !!value.length;
        },
        changeName() {
            if (this.item.name)
                this.item.qName = this.item.name;
        },
        handleImage(value) {
            this.item.banner = value;
        },
        handleConditional(value) {
            this.item.cond = !!value.length;
        },
        handleConditionalNo(value) {
            this.item.explain = !!value.length;
        }
    },
}
</script>

<style scoped>

</style>